import { Component } from '@angular/core';
import { AddLinkDialogComponent } from '@app/editor/add-link-dialog/add-link-dialog.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { NodeType } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { wrapInList } from '../menu/listLogic';

export function cmdInsertLink(sectionID: string) {
  const self = this as ServiceShare;
  const editor =
    self.ProsemirrorEditorsService.editorContainers[sectionID].editorView;

  if (editor) {
    const { from, to } = editor.state.selection;
    const text = editor.state.doc.textBetween(from, to);

    self.dialog
      .open(AddLinkDialogComponent, {
        width: '582px',
        panelClass: 'insert-figure-in-editor',
        data: { url: undefined, text: text },
      })
      .afterClosed()
      .subscribe((result: { url: string; text: string }) => {
        if (result && result.url && result.text) {
          const { url, text } = result;

          const mark = editor.state.schema.marks.link.create({
            href: url,
            title: text,
          });

          const newtextNode = editor.state.schema.text(text, [mark]);
          const tr = editor.state.tr.replaceRangeWith(from, to, newtextNode);
          editor.dispatch(tr);
        }
      });
  }
  return true;
}

export function cmdInsertList(listNode: NodeType, sectionID: string) {
  const self = this as ServiceShare;
  const editor =
    self.ProsemirrorEditorsService.editorContainers[sectionID].editorView;
  const wrapInBulletListFunc = wrapInList(listNode);

  if (editor) {
    wrapInBulletListFunc(editor.state, editor.dispatch, editor);
  }
  return true;
}

export function cmdInsertCitableEl(modal: any, sectionID: string) {
  const self = this as ServiceShare;
  const editor =
    self.ProsemirrorEditorsService.editorContainers[sectionID].editorView;

  if (editor && editor.state.selection.from == editor.state.selection.to) {
    self.dialog
      .open(modal, {
        width: '582px',
        // height: '90%',
        panelClass: 'insert-figure-in-editor',
        data: { view: editor },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result && result.citation) {
          const { citation } = result as {
            citation: {
              text: string;
              refCitationIDs: string[];
              citationLayout: number;
              sortOptions: any[];
            };
          };

          self.EditorsRefsManagerService.citateSelectedReferencesInEditor(
            citation,
            editor
          );
        }
      });
  }
  return true;
}

export function cmdInsertComment(sectionID: string) {
  const self = this as ServiceShare;
  const editor = self.ProsemirrorEditorsService.editorContainers[sectionID].editorView;
  if (editor && editor.state.selection.from !== editor.state.selection.to) {
    const editorContainer = document.getElementsByClassName('editor-container').item(0) as HTMLDivElement;
    const commentBtn = editorContainer.getElementsByClassName('commentsBtn').item(0) as HTMLButtonElement;

    if(commentBtn) {
      commentBtn.click();
    }
  }
  return true;
}
