import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AskBeforeDeleteComponent } from '@app/editor/dialogs/ask-before-delete/ask-before-delete.component';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { YdocService } from '@app/editor/services/ydoc.service';
import { uuidv4 } from 'lib0/random';
import { TextSelection } from 'prosemirror-state';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { taxonMarkData, ydocTaxon, ydocTaxonsObj } from '../taxon.service';

@Component({
  selector: 'app-taxon',
  templateUrl: './taxon.component.html',
  styleUrls: ['./taxon.component.scss']
})
export class TaxonComponent implements OnInit,AfterViewInit,OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() taxon?: taxonMarkData;

  @Input() doneRenderingTaxonsSubject?: Subject<any>;
  @Output() doneRenderingTaxonsSubjectChange = new EventEmitter<Subject<any>>();

  @Output() selected = new EventEmitter<boolean>();
  
  taxonsDataObj: ydocTaxonsObj;
  taxonsMap: any;
  previewMode: any;

  constructor(
    public sharedService:ServiceShare,
    public ydocService:YdocService,
    public dialog:MatDialog,
    private chnageDetection: ChangeDetectorRef
  ) {
    if (this.ydocService.editorIsBuild) {
      this.taxonsMap = this.ydocService.TaxonsMap
    }
    this.previewMode = sharedService.ProsemirrorEditorsService!.previewArticleMode
    this.sharedService.TaxonService.lastSelectedTaxonMarkSubject.subscribe((taxon) => {
      if(this.ydocService.curUserAccess&&this.ydocService.curUserAccess=='Reader'){
        return
      }
      if (this.taxon.taxonMarkId == taxon.taxonMarkId) {
        this.selected.emit(true);
      } else {
        this.selected.emit(false);
      }
    })
  }

  taxonsDataObjSubscription: Subscription;

  ngOnInit(): void {
    this.taxonsDataObj = this.taxonsMap.get('taxonsDataObj')
    this.taxon.taxonsData = this.taxonsDataObj[this.taxon.taxonTxt] || {
      scientificName: this.taxon.taxonTxt, 
      rank: '',
      author: '',
      classification: '',
      description: '',
      img: 'https://media.licdn.com/dms/image/C560BAQHMnA03XDdf3w/company-logo_200_200/0/1519855918965?e=2147483647&v=beta&t=J3kUMZwIphc90TFKH5oOO9Sa9K59fimgJf-s_okU3zs',
      title: uuidv4()
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  removeThisTaxon(taxonTxt) {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { objName: taxonTxt, type: 'currentTaxon' },
      panelClass: 'ask-before-delete-dialog',
    })
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.sharedService.TaxonService.removeSingleTaxon(this.taxon);
        this.sharedService.TaxonService.removeTaxonFromYdoc(this.taxon.taxonTxt);
      }
    })
  }

  removeAllOccurrencesOfTaxon(taxonTxt) {
    let dialogRef = this.dialog.open(AskBeforeDeleteComponent, {
      data: { objName: taxonTxt, type: 'allOccurrencesTaxon' },
      panelClass: 'ask-before-delete-dialog',
    })
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.sharedService.TaxonService.removeAllTaxon(this.taxon);
        this.sharedService.TaxonService.removeTaxonFromYdoc(this.taxon.taxonTxt);
      }
    })
  }

  selectTaxon() {
    let view = this.sharedService.ProsemirrorEditorsService.editorContainers[this.taxon.section].editorView;
    let actualTaxon: taxonMarkData
    let allTaxons = this.sharedService.TaxonService.taxonsMarksObj
    Object.keys(allTaxons).forEach((taxonid) => {
      let tax = allTaxons[taxonid]
      if (tax && tax.taxonMarkId == this.taxon.taxonMarkId) {
        actualTaxon = tax
      }
    })
    if (actualTaxon) {
      view.focus()
      view.dispatch(view.state.tr.setSelection(new TextSelection(view.state.doc.resolve(actualTaxon.pmDocStartPos), view.state.doc.resolve(actualTaxon.pmDocStartPos))).setMeta('selected-comment',true))
      this.sharedService.ProsemirrorEditorsService.dispatchEmptyTransaction()
    }
  }

  taxonIsChangedInYdoc() {
    this.doneRenderingTaxonsSubject.next('change_in_taxons');
    this.chnageDetection.detectChanges();
  }
  checkIfTaxonHasChanged(taxonInYdoc: any) {
    let changed = false;
    if (taxonInYdoc) {
      if (taxonInYdoc.title != this.taxonsDataObj.title) {
        changed = true;
      }
    } else {
      // taxon deleted
      changed = true;
    }
    if (changed && taxonInYdoc) {
      this.taxon.taxonsData = JSON.parse(JSON.stringify(taxonInYdoc))
      setTimeout(() => {
        this.taxonIsChangedInYdoc()
      }, 20)
    }
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.doneRenderingTaxonsSubject.next('rendered')
    }, 10);
    this.sharedService.TaxonService.taxonsDataObjSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((taxonsObj) => {
      let ydocTaxonInstance = taxonsObj[this.taxon.taxonTxt]
      this.checkIfTaxonHasChanged(ydocTaxonInstance);
    })
  }
}
