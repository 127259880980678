import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {APP_CONFIG, AppConfig} from "@core/services/app-config";

@Injectable({
  providedIn: 'root'
})
export class StandaloneGuard  implements CanActivate {
  constructor(public router: Router, @Inject(APP_CONFIG) readonly config: AppConfig) { };

  canActivate(): boolean {
    if(this.config.standalone == undefined || this.config.standalone) {
      return true;
    } else if (this.config.standalone == false) {
      this.router.navigate(['home']);
      return false;
    } else {
      this.router.navigate(['404']);
      return false;
    }
  }
}
