import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { YdocService } from '../services/ydoc.service';
import {interval, Subscription} from 'rxjs';
import { Map } from 'yjs';

import { TreeService } from './tree-service/tree.service';
import { articleSection, basicArticleSection } from '../utils/interfaces/articleSection';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponentComponent } from './snack-bar-error-component/snack-bar-error-component.component';
import { UntypedFormControl } from '@angular/forms';
import { ProsemirrorEditorsService } from '../services/prosemirror-editors.service';
import { debounce } from 'rxjs/operators';
import { TextSelection } from 'prosemirror-state';
import { changeVersionSubject } from '../../y-prosemirror-src/plugins/sync-plugin.js'
import { VersionChange } from '../versions-section/version/version.component';


@Component({
  selector: 'app-meta-data-tree',
  templateUrl: './meta-data-tree.component.html',
  styleUrls: ['./meta-data-tree.component.scss']
})

export class MetaDataTreeComponent implements OnInit, AfterViewInit, OnDestroy {
  articleSectionsStructure: basicArticleSection[];
  errorDuration = 4;

  searchForm = new UntypedFormControl('');
  @ViewChild('input', { read: ElementRef }) input?: ElementRef;

  metadataMap?:Map<any>

  subscriber = new Subscription();

  constructor(
    public treeService: TreeService,
    private ydocService: YdocService,
    private _snackBar: MatSnackBar, 
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    ){
    this.treeService.errorSnackbarSubject.subscribe((data)=>{
      this._snackBar.openFromComponent(SnackBarErrorComponentComponent, {
        panelClass:'snackbar-error',

        duration: this.errorDuration * 1000,

      });
    })
  }
  ngOnInit(){
    if (this.ydocService.editorIsBuild) {
      this.initSections();
    }
    this.subscriber.add(this.ydocService.ydocStateObservable.subscribe(({event}) => {
      if (event == 'docIsBuild') {
       this.initSections();
      }
    }));
    this.subscriber.add(changeVersionSubject.subscribe((data: any) => {
      if(data.change == VersionChange.VERSION_CHANGE) {
        this.initSections()
      } else if (data.cahnge == VersionChange.RETURN_TO_NEWEST) {
        this.initSections()
      }
    }))
  }

  initSections(): void {
    this.articleSectionsStructure = this.ydocService.articleStructureMap.get('articleSectionsStructure');
    this.showAll(this.articleSectionsStructure);
    this.metadataMap = this.ydocService.articleStructureMap;
    this.treeService.initTreeList(this.articleSectionsStructure);
  }

  ngAfterViewInit(){
    this.setFormControlChangeListener();
  }

  showAll(sections: basicArticleSection[]) {
    for(const section of sections) {
      section.shouldNotShow = false;
      if (section.children && section.children.length > 0) {
        this.showAll(section.children);
      }
    }
  }

  searchSections(sections: basicArticleSection[], searchValue: string, parent?: basicArticleSection) {
    let foundSections = [];
    for (const section of sections) {

      if (section.label && section.label.toLocaleLowerCase().includes(searchValue)) {
         foundSections.push(section);
         section.shouldNotShow = false;
      } else {
        section.shouldNotShow = true;
      }
      
      if (section.children && section.children.length > 0) {
        let foundChildrenSections;
        if(parent) {
          foundChildrenSections = this.searchSections(section.children, searchValue);
        } else {
          foundChildrenSections = this.searchSections(section.children, searchValue, section);
        }
        foundSections = foundSections.concat(foundChildrenSections);
      }
     
      if(section.children && section.children.find(ch => ch.shouldNotShow == false)) {
        section.shouldNotShow = false;
      }
    }
    return foundSections;
  }

  searching: boolean = false;
  searchIndex: number = 0;
  searchResults?: articleSection[];

  setFormControlChangeListener() {
    this.searchForm.valueChanges.pipe(debounce(val => interval(700))).subscribe((val) => {
      if (val && val != "" && typeof val == 'string' && val.trim().length > 0) {
        const searchVal = val.toLocaleLowerCase();
        const foundSections = this.searchSections(this.articleSectionsStructure, searchVal);

        if (foundSections.length > 0) {
          this.searchResults = foundSections;
          this.searchIndex = 0;
          this.selectSection(foundSections[0]);
          this.input.nativeElement.focus();
          this.searching = true;
        } else {
          this.searching = false;
        }
      } else {
        this.showAll(this.articleSectionsStructure);
        this.searching = false;
      }
    })
  }

  selectSection(section: articleSection) {
    const editorContainer = this.prosemirrorEditorsService.editorContainers[section.sectionID];
    if (editorContainer) {
      const editorView = editorContainer.editorView;
      const { doc } = editorView.state;
      editorView.focus();
      editorView.dispatch(editorView.state.tr.scrollIntoView().setSelection(TextSelection.create(doc, doc.firstChild.nodeSize)));
    }
  }

  selectPrevSectionFromSearch() {
    this.searchIndex--;
    const section = this.searchResults[this.searchIndex];
    this.selectSection(section);
  }

  selectNextSectionFromSearch() {
    this.searchIndex++;
    const section = this.searchResults[this.searchIndex];
    this.selectSection(section);
  }

  endSearch() {
    this.searching = false;
    this.searchIndex = 0;
    this.searchResults = [];
    this.searchForm.setValue('');
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}


