import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DOMParser, DOMSerializer, Node } from 'prosemirror-model';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { schema } from '@app/editor/utils/Schema';
import { citableTable } from '@app/editor/utils/interfaces/citableTables';
import { getHtmlFromFragment } from '@app/editor/utils/prosemirrorHelpers';

@Component({
  selector: 'app-citable-table',
  templateUrl: './citable-table.component.html',
  styleUrls: ['./citable-table.component.scss'],
})
export class CitableTableComponent implements OnInit {
  @Input() table?: citableTable;
  @Output() tableChange = new EventEmitter<citableTable>();
  @Input() tableIndex?: number;

  tableHeader = '';

  urlSafe?: SafeResourceUrl;
  constructor(private serviceShare: ServiceShare) {}

  ngOnInit(): void {
    let DOMPMSerializer = DOMSerializer.fromSchema(schema);
    const div = document.createElement('div');
    div.innerHTML =
      this.serviceShare.ProsemirrorEditorsService.editorContainers[
        this.table.tableID
      ].containerDiv.innerHTML;
    const tableNode = DOMParser.fromSchema(schema).parse(div.firstChild);

    const getDataFromNode = (node: Node) => {
      node.content.forEach((node1, offset, index) => {
        if (node1.type.name == 'table_header_container') {
          node1.content.forEach((node2) => {
            if (node2.type.name == 'table_description') {
              this.table.header = getHtmlFromFragment(
                node2.content,
                DOMPMSerializer
              );
            }
          });
        } else {
          getDataFromNode(node1);
        }
      });
    };
    getDataFromNode(tableNode);
  }
}
