import { PluginKey } from 'prosemirror-state' // eslint-disable-line

/**
 * The unique prosemirror plugin key for syncPlugin
 *
 * @public
 */
export const ySyncPluginKey = new PluginKey('y-sync')

export let ySyncPluginKeyObj = {
    ySyncPluginKey: ySyncPluginKey,
    origin: ySyncPluginKey,
  }
  /**
   * The unique prosemirror plugin key for undoPlugin
   *
   * @public
   */
export const yUndoPluginKey = new PluginKey('y-undo')

/**
 * The unique prosemirror plugin key for cursorPlugin
 *
 * @public
 */
export const yCursorPluginKey = new PluginKey('yjs-cursor')
