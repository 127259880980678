import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcasterService } from '@core/services/broadcaster.service';
import { CONSTANTS } from '@core/services/constants';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { of, pipe, Subscription } from 'rxjs';
import { UserModel } from '@core/models/user.model';
import { OauthClient } from '@app/core/services/oauth-client';

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.scss']
})
export class OauthCallbackComponent implements OnInit {
  returnUrl: string;
  hasError: boolean;
  constructor(private readonly authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private _broadcaster: BroadcasterService,
              private readonly oauthClient: OauthClient) { }

  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'.toString()] || '/';
    this._broadcaster.broadcast(CONSTANTS.SHOW_LOADER, true);

    this.hasError = false;
    const begin = performance.now();
    this.oauthClient.lpClient.handleRedirectCallback().then( async signInResult => {
      console.log('signInResult', signInResult);
      if(!signInResult) {
        await this.oauthClient.lpClient.signIn();
        return false;
      }
      const token = await this.oauthClient.lpClient.getToken();
      console.log('TOKEN', token);
      this.authService.storeToken(token);
      const loginSubscr = this.authService.getUserInfo(token).pipe(take(1))
        .subscribe((user: UserModel | undefined) => {
        if (user) {
          console.log('User', user);
          const requestDuration = `${performance.now() - begin}`;
          const returnUrl = sessionStorage.getItem("return_uri");
          console.log('returnUrl', returnUrl)
          if(returnUrl) {
            sessionStorage.removeItem(`return_uri`);
            this.router.navigate([returnUrl]);
          } else {
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.hasError = true;
        }
      });
    });
  }

}
