export const treatmentSectionsSubsection = {
  "id": 9981,
  "name": "Enter subsection title",
  "label": "{{data.sectionTreeTitle}}",
  edit: {active: true, main: true},
  add: {active: true, main: true},
  delete: {active: false, main: false},
  select: {active: false, main: false},
  "schema": {
    "components": [
      {
        "label": "Enter title of this subsection",
        "placeholder": "Enter subsection title",
        "autoExpand": false,
        "tableView": true,
        "key": "sectionTreeTitle",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": 'Enter title of this subsection',
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sa"
      },
      {
        "label": "Content",
        "placeholder": "Content",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sb"
      }
    ]
  },
  "sections": null,
  "template": `<ng-container *ngIf='data.paragraph'>
	<h5 style="padding-left: 40px;font-weight: bold !important;" contenteditableNode='false'>
		<p contenteditableNode="false" style="font-weight: bold !important;" class="set-align-left" formControlName="sectionTreeTitle">
		</p>
	</h5>
	<inline-block-container contenteditableNode='false' style="display: block;padding-left: 40px;">
		<form-field contenteditableNode="true" class="set-align-left" formControlName="paragraph">
		</form-field>
	</inline-block-container>
</ng-container>`,
  "type": 0,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}
