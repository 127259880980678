import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogSwUpdatesService {

  constructor(appRef: ApplicationRef,updates: SwUpdate) {
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
    
    updates.versionUpdates.pipe(
      filter(event => event.type == "VERSION_READY")//UpdateAvailableEvent
    ).subscribe({
      next: (event: VersionReadyEvent) => {
        /* let confirmDialog = confirm('A new version of the application is available.Your local one will be updated now.'); */
        updates.activateUpdate().then((isActivated: boolean) => {
          console.log("isActivated", isActivated);
          
          document.location.reload()
        });
      },
      error: (err) => {
        console.log(err);
      }
    })

    updates.versionUpdates.pipe(
      filter(event => event.type === 'VERSION_READY')
    ).subscribe({
      next: (event: VersionReadyEvent) => {
        console.log('Current version is', event.currentVersion);
        console.log('Available version is', event.latestVersion);
        updates.activateUpdate()
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
}
