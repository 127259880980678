export const materialStructure = {
  "categories": {
    "Record-level": {
      "name": "Record-level",
      "entries": [
        {
          "localName": "accessRights",
          "label": "Access Rights",
          "description": "Information about who can access the resource or an indication of its security status.",
          "examples": "`not-for-profit use only` (string literal example); `https://www.fieldmuseum.org/field-museum-natural-history-conditions-and-suggested-norms-use-collections-data-and-images` (URI example)",
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/accessRights"
        },
        {
          "localName": "basisOfRecord",
          "label": "Basis of Record",
          "description": "The specific nature of the data record.",
          "examples": "`PreservedSpecimen`; `FossilSpecimen`; `LivingSpecimen`; `MaterialSample`; `Event`; `HumanObservation`; `MachineObservation`; `Taxon`; `Occurrence`; `MaterialCitation`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/basisOfRecord"
        },
        {
          "localName": "bibliographicCitation",
          "label": "Bibliographic Citation",
          "description": "A bibliographic reference for the resource.",
          "examples": "`Museum of Vertebrate Zoology, UC Berkeley. MVZ Mammal Collection (Arctos). Record ID: http://arctos.database.museum/guid/MVZ:Mamm:165861?seid=101356. Source: http://ipt.vertnet.org:8080/ipt/resource.do?r=mvz_mammal.` (Occurrence example); `https://www.gbif.org/species/2439608 Source: GBIF Taxonomic Backbone` (Taxon example); `Rand, K.M., Logerwell, E.A. The first demersal trawl survey of benthic fish and invertebrates in the Beaufort Sea since the late 1970s. Polar Biol 34, 475–488 (2011). https://doi.org/10.1007/s00300-010-0900-2` (Event example)",
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/bibliographicCitation"
        },
        {
          "localName": "collectionCode",
          "label": "Collection Code",
          "description": "The name, acronym, coden, or initialism identifying the collection or data set from which the record was derived.",
          "examples": "`Mammals`; `Hildebrandt`; `EBIRD`; `VP`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/collectionCode"
        },
        {
          "localName": "collectionID",
          "label": "Collection ID",
          "description": "An identifier for the collection or dataset from which the record was derived.",
          "examples": "`https://www.gbif.org/grscicoll/collection/fbd3ed74-5a21-4e01-b86a-33d36f032d9c`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/collectionID"
        },
        {
          "localName": "dataGeneralizations",
          "label": "Data Generalizations",
          "description": "Actions taken to make the shared data less specific or complete than in its original form. Suggests that alternative data of higher quality may be available on request.",
          "examples": "`Coordinates generalized from original GPS coordinates to the nearest half degree grid cell`.",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/dataGeneralizations"
        },
        {
          "localName": "datasetID",
          "label": "Dataset ID",
          "description": "An identifier for the set of data. May be a global unique identifier or an identifier specific to a collection or institution.",
          "examples": "`b15d4952-7d20-46f1-8a3e-556a512b04c5`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/datasetID"
        },
        {
          "localName": "datasetName",
          "label": "Dataset Name",
          "description": "The name identifying the data set from which the record was derived.",
          "examples": "`Grinnell Resurvey Mammals`; `Lacey Ctenomys Recaptures`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/datasetName"
        },
        {
          "localName": "dynamicProperties",
          "label": "Dynamic Properties",
          "description": "A list of additional measurements, facts, characteristics, or assertions about the record. Meant to provide a mechanism for structured content.",
          "examples": "`{\"heightInMeters\":1.5}`; `{\"targusLengthInMeters\":0.014, \"weightInGrams\":120}`; `{\"natureOfID\":\"expert identification\", \"identificationEvidence\":\"cytochrome B sequence\"}`; `{\"relativeHumidity\":28, \"airTemperatureInCelsius\":22, \"sampleSizeInKilograms\":10}`; `{\"aspectHeading\":277, \"slopeInDegrees\":6}`; `{\"iucnStatus\":\"vulnerable\", \"taxonDistribution\":\"Neuquén, Argentina\"}`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/dynamicProperties"
        },
        {
          "localName": "informationWithheld",
          "label": "Information Withheld",
          "description": "Additional information that exists, but that has not been shared in the given record.",
          "examples": "`location information not given for endangered species`; `collector identities withheld | ask about tissue samples`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/informationWithheld"
        },
        {
          "localName": "institutionCode",
          "label": "Institution Code",
          "description": "The name (or acronym) in use by the institution having custody of the object(s) or information referred to in the record.",
          "examples": "`MVZ`; `FMNH`; `CLO`; `UCMP`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/institutionCode"
        },
        {
          "localName": "institutionID",
          "label": "Institution ID",
          "description": "An identifier for the institution having custody of the object(s) or information referred to in the record.",
          "examples": "`https://ror.org/015hz7p22`; `http://grscicoll.org/institution/museum-southwestern-biology`; `https://www.gbif.org/grscicoll/institution/e3d4dcc4-81e2-444c-8a5c-41d1044b5381`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/institutionID"
        },
        {
          "localName": "language",
          "label": "Language",
          "description": "A language of the resource.",
          "examples": null,
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/language"
        },
        {
          "localName": "language",
          "label": "Language",
          "description": "A language of the resource.",
          "examples": "`en` (for English); `es` (for Spanish)",
          "organizedIn": "http://purl.org/dc/elements/1.1/",
          "status": "recommended",
          "iri": "http://purl.org/dc/elements/1.1/language"
        },
        {
          "localName": "license",
          "label": "License",
          "description": "A legal document giving official permission to do something with the resource.",
          "examples": "`http://creativecommons.org/publicdomain/zero/1.0/legalcode`; `http://creativecommons.org/licenses/by/4.0/legalcode`",
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/license"
        },
        {
          "localName": "Location",
          "label": "Location",
          "description": "A spatial region or named place.",
          "examples": "The municipality of San Carlos de Bariloche, Río Negro, Argentina. The place defined by a georeference.",
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/Location"
        },
        {
          "localName": "modified",
          "label": "Date Modified",
          "description": "The most recent date-time on which the resource was changed.",
          "examples": "`1963-03-08T14:07-0600` (8 Mar 1963 at 2:07pm in the time zone six hours earlier than UTC); `2009-02-20T08:40Z` (20 February 2009 8:40am UTC); `2018-08-29T15:19` (3:19pm local time on 29 August 2018); `1809-02-12` (some time during 12 February 1809); `1906-06` (some time in June 1906); `1971` (some time in the year 1971); `2007-03-01T13:00:00Z/2008-05-11T15:30:00Z` (some time during the interval between 1 March 2007 1pm UTC and 11 May 2008 3:30pm UTC); `1900/1909` (some time during the interval between the beginning of the year 1900 and the end of the year 1909); `2007-11-13/15` (some time in the interval between 13 November 2007 and 15 November 2007)",
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/modified"
        },
        {
          "localName": "ownerInstitutionCode",
          "label": "Owner Institution Code",
          "description": "The name (or acronym) in use by the institution having ownership of the object(s) or information referred to in the record.",
          "examples": "`NPS`; `APN`; `InBio`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/ownerInstitutionCode"
        },
        {
          "localName": "references",
          "label": "References",
          "description": "A related resource that is referenced, cited, or otherwise pointed to by the described resource.",
          "examples": "`http://arctos.database.museum/guid/MVZ:Mamm:165861` (MaterialSample example); `https://www.catalogueoflife.org/data/taxon/32664` (Taxon example)",
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/references"
        },
        {
          "localName": "rightsHolder",
          "label": "Rights Holder",
          "description": "A person or organization owning or managing rights over the resource.",
          "examples": "`The Regents of the University of California`",
          "organizedIn": "http://purl.org/dc/terms/",
          "status": "recommended",
          "iri": "http://purl.org/dc/terms/rightsHolder"
        },
        {
          "localName": "type",
          "label": "Type",
          "description": "The nature or genre of the resource.",
          "examples": "`StillImage`; `MovingImage`; `Sound`; `PhysicalObject`; `Event`; `Text`",
          "organizedIn": "http://purl.org/dc/elements/1.1/",
          "status": "recommended",
          "iri": "http://purl.org/dc/elements/1.1/type"
        }
      ],
      "label": "Record-level",
      "iri": ""
    },
    "Occurrence": {
      "name": "Occurrence",
      "entries": [
        {
          "localName": "associatedMedia",
          "label": "Associated Media",
          "description": "A list (concatenated and separated) of identifiers (publication, global unique identifier, URI) of media associated with the dwc:Occurrence.",
          "examples": "`https://arctos.database.museum/media/10520962 | https://arctos.database.museum/media/10520964`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/associatedMedia"
        },
        {
          "localName": "associatedOccurrences",
          "label": "Associated Occurrences",
          "description": "A list (concatenated and separated) of identifiers of other dwc:Occurrence records and their associations to this dwc:Occurrence.",
          "examples": "`\"parasite collected from\":\"https://arctos.database.museum/guid/MSB:Mamm:215895?seid=950760\"`; `\"encounter previous to\":\"http://arctos.database.museum/guid/MSB:Mamm:292063?seid=3175067\" | \"encounter previous to\":\"http://arctos.database.museum/guid/MSB:Mamm:292063?seid=3177393\" | \"encounter previous to\":\"http://arctos.database.museum/guid/MSB:Mamm:292063?seid=3177394\" | \"encounter previous to\":\"http://arctos.database.museum/guid/MSB:Mamm:292063?seid=3177392\" | \"encounter previous to\":\"http://arctos.database.museum/guid/MSB:Mamm:292063?seid=3609139\"`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/associatedOccurrences"
        },
        {
          "localName": "associatedReferences",
          "label": "Associated References",
          "description": "A list (concatenated and separated) of identifiers (publication, bibliographic reference, global unique identifier, URI) of literature associated with the dwc:Occurrence.",
          "examples": "`http://www.sciencemag.org/cgi/content/abstract/322/5899/261`; `Christopher J. Conroy, Jennifer L. Neuwald. 2008. Phylogeographic study of the California vole, Microtus californicus Journal of Mammalogy, 89(3):755-767.`; `Steven R. Hoofer and Ronald A. Van Den Bussche. 2001. Phylogenetic Relationships of Plecotine Bats and Allies Based on Mitochondrial Ribosomal Sequences. Journal of Mammalogy 82(1):131-137. | Walker, Faith M., Jeffrey T. Foster, Kevin P. Drees, Carol L. Chambers. 2014. Spotted bat (Euderma maculatum) microsatellite discovery using illumina sequencing. Conservation Genetics Resources.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/associatedReferences"
        },
        {
          "localName": "associatedSequences",
          "label": "Associated Sequences",
          "description": "A list (concatenated and separated) of identifiers (publication, global unique identifier, URI) of genetic sequence information associated with the dwc:Occurrence.",
          "examples": "`http://www.ncbi.nlm.nih.gov/nuccore/U34853.1`; `http://www.ncbi.nlm.nih.gov/nuccore/GU328060 | http://www.ncbi.nlm.nih.gov/nuccore/AF326093`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/associatedSequences"
        },
        {
          "localName": "associatedTaxa",
          "label": "Associated Taxa",
          "description": "A list (concatenated and separated) of identifiers or names of dwc:Taxon records and the associations of this dwc:Occurrence to each of them.",
          "examples": "`\"host\":\"Quercus alba\"`; `\"host\":\"gbif.org/species/2879737\"`; `\"parasitoid of\":\"Cyclocephala signaticollis\" | \"predator of\":\"Apis mellifera\"`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/associatedTaxa"
        },
        {
          "localName": "behavior",
          "label": "Behavior",
          "description": "The behavior shown by the subject at the time the dwc:Occurrence was recorded.",
          "examples": "`roosting`; `foraging`; `running`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/behavior"
        },
        {
          "localName": "caste",
          "label": "Caste",
          "description": "Categorisation of individuals for eusocial species (including some mammals and arthropods).",
          "examples": "`queen`; `male alate`; `intercaste`; `minor worker`; `soldier`; `ergatoid`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/caste"
        },
        {
          "localName": "catalogNumber",
          "label": "Catalog Number",
          "description": "An identifier (preferably unique) for the record within the data set or collection.",
          "examples": "`145732`; `145732a`; `2008.1334`; `R-4313`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/catalogNumber"
        },
        {
          "localName": "degreeOfEstablishment",
          "label": "Degree of Establishment",
          "description": "The degree to which a dwc:Organism survives, reproduces, and expands its range at the given place and time.",
          "examples": "`native`; `captive`; `cultivated`; `released`; `failing`; `casual`; `reproducing`; `established`; `colonising`; `invasive`; `widespreadInvasive`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/degreeOfEstablishment"
        },
        {
          "localName": "disposition",
          "label": "Disposition",
          "description": "The current state of a specimen with respect to the collection identified in dwc:collectionCode or dwc:collectionID.",
          "examples": "`in collection`; `missing`; `voucher elsewhere`; `duplicates elsewhere`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/disposition"
        },
        {
          "localName": "establishmentMeans",
          "label": "Establishment Means",
          "description": "Statement about whether a dwc:Organism has been introduced to a given place and time through the direct or indirect activity of modern humans.",
          "examples": "`native`; `nativeReintroduced`; `introduced`; `introducedAssistedColonisation`; `vagrant`; `uncertain`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/establishmentMeans"
        },
        {
          "localName": "georeferenceVerificationStatus",
          "label": "Georeference Verification Status",
          "description": "A categorical description of the extent to which the georeference has been verified to represent the best possible spatial description for the dcterms:Location of the dwc:Occurrence.",
          "examples": "`unable to georeference`; `requires georeference`; `requires verification`; `verified by data custodian`; `verified by contributor`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/georeferenceVerificationStatus"
        },
        {
          "localName": "individualCount",
          "label": "Individual Count",
          "description": "The number of individuals present at the time of the dwc:Occurrence.",
          "examples": "`0`; `1`; `25`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/individualCount"
        },
        {
          "localName": "lifeStage",
          "label": "Life Stage",
          "description": "The age class or life stage of the dwc:Organism(s) at the time the dwc:Occurrence was recorded.",
          "examples": "`zygote`; `larva`; `juvenile`; `adult`; `seedling`; `flowering`; `fruiting`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/lifeStage"
        },
        {
          "localName": "occurrenceID",
          "label": "Occurrence ID",
          "description": "An identifier for the dwc:Occurrence (as opposed to a particular digital record of the dwc:Occurrence). In the absence of a persistent global unique identifier, construct one from a combination of identifiers in the record that will most closely make the dwc:occurrenceID globally unique.",
          "examples": "`http://arctos.database.museum/guid/MSB:Mamm:233627`; `000866d2-c177-4648-a200-ead4007051b9`; `urn:catalog:UWBM:Bird:89776`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/occurrenceID"
        },
        {
          "localName": "occurrenceRemarks",
          "label": "Occurrence Remarks",
          "description": "Comments or notes about the dwc:Occurrence.",
          "examples": "`found dead on road`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/occurrenceRemarks"
        },
        {
          "localName": "occurrenceStatus",
          "label": "Occurrence Status",
          "description": "A statement about the presence or absence of a dwc:Taxon at a dcterms:Location.",
          "examples": "`present`; `absent`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/occurrenceStatus"
        },
        {
          "localName": "organismQuantity",
          "label": "Organism Quantity",
          "description": "A number or enumeration value for the quantity of dwc:Organisms.",
          "examples": "`27` (organismQuantity) with `individuals` (organismQuantityType); `12.5` (organismQuantity) with `% biomass` (organismQuantityType); `r` (organismQuantity) with `Braun-Blanquet Scale` (organismQuantityType); `many` (organismQuantity) with `individuals` (organismQuantityType)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/organismQuantity"
        },
        {
          "localName": "organismQuantityType",
          "label": "Organism Quantity Type",
          "description": "The type of quantification system used for the quantity of dwc:Organisms.",
          "examples": "`27` (organismQuantity) with `individuals` (organismQuantityType); `12.5` (organismQuantity) with `% biomass` (organismQuantityType); `r` (organismQuantity) with `Braun-Blanquet Scale` (organismQuantityType); `many` (organismQuantity) with `individuals` (organismQuantityType)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/organismQuantityType"
        },
        {
          "localName": "otherCatalogNumbers",
          "label": "Other Catalog Numbers",
          "description": "A list (concatenated and separated) of previous or alternate fully qualified catalog numbers or other human-used identifiers for the same dwc:Occurrence, whether in the current or any other data set or collection.",
          "examples": "`FMNH:Mammal:1234`; `NPS YELLO6778 | MBG 33424`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/otherCatalogNumbers"
        },
        {
          "localName": "pathway",
          "label": "Pathway",
          "description": "The process by which a dwc:Organism came to be in a given place at a given time.",
          "examples": "`releasedForUse`; `otherEscape`; `transportContaminant`; `transportStowaway`; `corridor`; `unaided`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/pathway"
        },
        {
          "localName": "preparations",
          "label": "Preparations",
          "description": "A list (concatenated and separated) of preparations and preservation methods for a specimen.",
          "examples": "`fossil`; `cast`; `photograph`; `DNA extract`; `skin | skull | skeleton`; `whole animal (ETOH) | tissue (EDTA)`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/preparations"
        },
        {
          "localName": "recordedBy",
          "label": "Recorded By",
          "description": "A list (concatenated and separated) of names of people, groups, or organizations responsible for recording the original dwc:Occurrence. The primary collector or observer, especially one who applies a personal identifier (dwc:recordNumber), should be listed first.",
          "examples": "`José E. Crespo`; `Oliver P. Pearson | Anita K. Pearson` (where the value in recordNumber `OPP 7101` corresponds to the collector number for the specimen in the field catalog of Oliver P. Pearson)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/recordedBy"
        },
        {
          "localName": "recordedByID",
          "label": "Recorded By ID",
          "description": "A list (concatenated and separated) of the globally unique identifier for the person, people, groups, or organizations responsible for recording the original dwc:Occurrence.",
          "examples": "`https://orcid.org/0000-0002-1825-0097` (for an individual); `https://orcid.org/0000-0002-1825-0097 | https://orcid.org/0000-0002-1825-0098` (for a list of people)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/recordedByID"
        },
        {
          "localName": "recordNumber",
          "label": "Record Number",
          "description": "An identifier given to the dwc:Occurrence at the time it was recorded. Often serves as a link between field notes and a dwc:Occurrence record, such as a specimen collector's number.",
          "examples": "`OPP 7101`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/recordNumber"
        },
        {
          "localName": "reproductiveCondition",
          "label": "Reproductive Condition",
          "description": "The reproductive condition of the biological individual(s) represented in the dwc:Occurrence.",
          "examples": "`non-reproductive`; `pregnant`; `in bloom`; `fruit-bearing`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/reproductiveCondition"
        },
        {
          "localName": "sex",
          "label": "Sex",
          "description": "The sex of the biological individual(s) represented in the dwc:Occurrence.",
          "examples": "`female`; `male`; `hermaphrodite`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/sex"
        },
        {
          "localName": "vitality",
          "label": "Vitality",
          "description": "An indication of whether a dwc:Organism was alive or dead at the time of collection or observation.",
          "examples": "`alive`; `dead`; `mixedLot`; `uncertain`; `notAssessed`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Occurrence",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/vitality"
        }
      ],
      "label": "Occurrence",
      "iri": "http://rs.tdwg.org/dwc/terms/Occurrence"
    },
    "Organism": {
      "name": "Organism",
      "entries": [
        {
          "localName": "associatedOrganisms",
          "label": "Associated Organisms",
          "description": "A list (concatenated and separated) of identifiers of other dwc:Organisms and the associations of this dwc:Organism to each of them.",
          "examples": "`\"sibling of\":\"http://arctos.database.museum/guid/DMNS:Mamm:14171\"`; `\"parent of\":\"http://arctos.database.museum/guid/MSB:Mamm:196208\" | \"parent of\":\"http://arctos.database.museum/guid/MSB:Mamm:196523\" | \"sibling of\":\"http://arctos.database.museum/guid/MSB:Mamm:142638\"`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Organism",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/associatedOrganisms"
        },
        {
          "localName": "organismID",
          "label": "Organism ID",
          "description": "An identifier for the dwc:Organism instance (as opposed to a particular digital record of the dwc:Organism). May be a globally unique identifier or an identifier specific to the data set.",
          "examples": "`http://arctos.database.museum/guid/WNMU:Mamm:1249`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Organism",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/organismID"
        },
        {
          "localName": "organismName",
          "label": "Organism Name",
          "description": "A textual name or label assigned to a dwc:Organism instance.",
          "examples": "`Huberta`; `Boab Prison Tree`; `J pod`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Organism",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/organismName"
        },
        {
          "localName": "organismRemarks",
          "label": "Organism Remarks",
          "description": "Comments or notes about the dwc:Organism instance.",
          "examples": "`One of a litter of six`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Organism",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/organismRemarks"
        },
        {
          "localName": "organismScope",
          "label": "Organism Scope",
          "description": "A description of the kind of dwc:Organism instance. Can be used to indicate whether the dwc:Organism instance represents a discrete organism or if it represents a particular type of aggregation.",
          "examples": "`multicellular organism`; `virus`; `clone`; `pack`; `colony`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Organism",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/organismScope"
        },
        {
          "localName": "previousIdentifications",
          "label": "Previous Identifications",
          "description": "A list (concatenated and separated) of previous assignments of names to the dwc:Organism.",
          "examples": "`Chalepidae`; `Pinus abies`; `Anthus sp., field ID by G. Iglesias | Anthus correndera, expert ID by C. Cicero 2009-02-12 based on morphology`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Organism",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/previousIdentifications"
        }
      ],
      "label": "Organism",
      "iri": "http://rs.tdwg.org/dwc/terms/Organism"
    },
    "MaterialSample": {
      "name": "MaterialSample",
      "entries": [
        {
          "localName": "materialSampleID",
          "label": "Material Sample ID",
          "description": "An identifier for the dwc:MaterialSample (as opposed to a particular digital record of the dwc:MaterialSample). In the absence of a persistent global unique identifier, construct one from a combination of identifiers in the record that will most closely make the dwc:materialSampleID globally unique.",
          "examples": "`06809dc5-f143-459a-be1a-6f03e63fc083`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MaterialSample",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/materialSampleID"
        },
        {
          "localName": "verbatimLabel",
          "label": "Verbatim Label",
          "description": "A serialized encoding intended to represent the literal, i.e., character by character, textual content of a label affixed on, near, or explicitly associated with a material entity, free from interpretation, translation, or transliteration.",
          "examples": "Examples can be found at https://dwc.tdwg.org/examples/verbatimLabel.",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MaterialSample",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimLabel"
        }
      ],
      "label": "Material Sample",
      "iri": "http://rs.tdwg.org/dwc/terms/MaterialSample"
    },
    "Event": {
      "name": "Event",
      "entries": [
        {
          "localName": "day",
          "label": "Day",
          "description": "The integer day of the month on which the dwc:Event occurred.",
          "examples": "`9`; `28`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/day"
        },
        {
          "localName": "endDayOfYear",
          "label": "End Day Of Year",
          "description": "The latest integer day of the year on which the dwc:Event occurred (1 for January 1, 365 for December 31, except in a leap year, in which case it is 366).",
          "examples": "`1` (1 January); `32` (1 February); `366` (31 December); `365` (30 December in a leap year, 31 December in a non-leap year)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/endDayOfYear"
        },
        {
          "localName": "eventDate",
          "label": "Event Date",
          "description": "The date-time or interval during which a dwc:Event occurred. For occurrences, this is the date-time when the dwc:Event was recorded. Not suitable for a time in a geological context.",
          "examples": "`1963-03-08T14:07-0600` (8 Mar 1963 at 2:07pm in the time zone six hours earlier than UTC); `2009-02-20T08:40Z` (20 February 2009 8:40am UTC); `2018-08-29T15:19` (3:19pm local time on 29 August 2018); `1809-02-12` (some time during 12 February 1809); `1906-06` (some time in June 1906); `1971` (some time in the year 1971); `2007-03-01T13:00:00Z/2008-05-11T15:30:00Z` (some time during the interval between 1 March 2007 1pm UTC and 11 May 2008 3:30pm UTC); `1900/1909` (some time during the interval between the beginning of the year 1900 and the end of the year 1909); `2007-11-13/15` (some time in the interval between 13 November 2007 and 15 November 2007)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/eventDate"
        },
        {
          "localName": "eventID",
          "label": "Event ID",
          "description": "An identifier for the set of information associated with a dwc:Event (something that occurs at a place and time). May be a global unique identifier or an identifier specific to the data set.",
          "examples": "`INBO:VIS:Ev:00009375`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/eventID"
        },
        {
          "localName": "eventRemarks",
          "label": "Event Remarks",
          "description": "Comments or notes about the dwc:Event.",
          "examples": "`After the recent rains the river is nearly at flood stage.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/eventRemarks"
        },
        {
          "localName": "eventTime",
          "label": "Event Time",
          "description": "The time or interval during which a dwc:Event occurred.",
          "examples": "`14:07-0600` (2:07pm in the time zone six hours earlier than UTC); `08:40:21Z` (8:40:21am UTC); `13:00:00Z/15:30:00Z` (the interval between 1pm UTC and 3:30pm UTC)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/eventTime"
        },
        {
          "localName": "eventType",
          "label": "Event Type",
          "description": "The nature of the dwc:Event.",
          "examples": "`Sample`; `Observation`; `Site Visit`; `Biotic Interaction`; `Bioblitz`; `Expedition`; `Survey`; `Project`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/eventType"
        },
        {
          "localName": "fieldNotes",
          "label": "Field Notes",
          "description": "One of a) an indicator of the existence of, b) a reference to (publication, URI), or c) the text of notes taken in the field about the dwc:Event.",
          "examples": "`Notes available in the Grinnell-Miller Library.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/fieldNotes"
        },
        {
          "localName": "fieldNumber",
          "label": "Field Number",
          "description": "An identifier given to the dwc:Event in the field. Often serves as a link between field notes and the dwc:Event.",
          "examples": "`RV Sol 87-03-08`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/fieldNumber"
        },
        {
          "localName": "habitat",
          "label": "Habitat",
          "description": "A category or description of the habitat in which the dwc:Event occurred.",
          "examples": "`oak savanna`; `pre-cordilleran steppe`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/habitat"
        },
        {
          "localName": "month",
          "label": "Month",
          "description": "The integer month in which the dwc:Event occurred.",
          "examples": "`1` (January); `10` (October)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/month"
        },
        {
          "localName": "parentEventID",
          "label": "Parent Event ID",
          "description": "An identifier for the broader dwc:Event that groups this and potentially other dwc:Events.",
          "examples": "`A1` (parentEventID to identify the main Whittaker Plot in nested samples, each with its own eventID - `A1:1`, `A1:2`).",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/parentEventID"
        },
        {
          "localName": "sampleSizeUnit",
          "label": "Sample Size Unit",
          "description": "The unit of measurement of the size (time duration, length, area, or volume) of a sample in a sampling dwc:Event.",
          "examples": "`minute`; `hour`; `day`; `metre`; `square metre`; `cubic metre`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/sampleSizeUnit"
        },
        {
          "localName": "sampleSizeValue",
          "label": "Sample Size Value",
          "description": "A numeric value for a measurement of the size (time duration, length, area, or volume) of a sample in a sampling dwc:Event.",
          "examples": "`5` (sampleSizeValue) with `metre` (sampleSizeUnit)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/sampleSizeValue"
        },
        {
          "localName": "samplingEffort",
          "label": "Sampling Effort",
          "description": "The amount of effort expended during a dwc:Event.",
          "examples": "`40 trap-nights`; `10 observer-hours`; `10 km by foot`; `30 km by car`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/samplingEffort"
        },
        {
          "localName": "samplingProtocol",
          "label": "Sampling Protocol",
          "description": "The names of, references to, or descriptions of the methods or protocols used during a dwc:Event.",
          "examples": "`UV light trap`; `mist net`; `bottom trawl`; `ad hoc observation | point count`; `Penguins from space: faecal stains reveal the location of emperor penguin colonies, https://doi.org/10.1111/j.1466-8238.2009.00467.x`; `Takats et al. 2001. Guidelines for Nocturnal Owl Monitoring in North America. Beaverhill Bird Observatory and Bird Studies Canada, Edmonton, Alberta. 32 pp., http://www.bsc-eoc.org/download/Owl.pdf`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/samplingProtocol"
        },
        {
          "localName": "startDayOfYear",
          "label": "Start Day Of Year",
          "description": "The earliest integer day of the year on which the dwc:Event occurred (1 for January 1, 365 for December 31, except in a leap year, in which case it is 366).",
          "examples": "`1` (1 January); `366` (31 December); `365` (30 December in a leap year, 31 December in a non-leap year)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/startDayOfYear"
        },
        {
          "localName": "verbatimEventDate",
          "label": "Verbatim EventDate",
          "description": "The verbatim original representation of the date and time information for a dwc:Event.",
          "examples": "`spring 1910`; `Marzo 2002`; `1999-03-XX`; `17IV1934`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimEventDate"
        },
        {
          "localName": "year",
          "label": "Year",
          "description": "The four-digit year in which the dwc:Event occurred, according to the Common Era Calendar.",
          "examples": "`1160`; `2008`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Event",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/year"
        }
      ],
      "label": "Event",
      "iri": "http://rs.tdwg.org/dwc/terms/Event"
    },
    "Location": {
      "name": "Location",
      "entries": [
        {
          "localName": "continent",
          "label": "Continent",
          "description": "The name of the continent in which the dcterms:Location occurs.",
          "examples": "`Africa`; `Antarctica`; `Asia`; `Europe`; `North America`; `Oceania`; `South America`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/continent"
        },
        {
          "localName": "coordinatePrecision",
          "label": "Coordinate Precision",
          "description": "A decimal representation of the precision of the coordinates given in the dwc:decimalLatitude and dwc:decimalLongitude.",
          "examples": "`0.00001` (normal GPS limit for decimal degrees); `0.000278` (nearest second); `0.01667` (nearest minute); `1.0` (nearest degree)",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/coordinatePrecision"
        },
        {
          "localName": "coordinateUncertaintyInMeters",
          "label": "Coordinate Uncertainty In Meters",
          "description": "The horizontal distance (in meters) from the given dwc:decimalLatitude and dwc:decimalLongitude describing the smallest circle containing the whole of the dcterms:Location. Leave the value empty if the uncertainty is unknown, cannot be estimated, or is not applicable (because there are no coordinates). Zero is not a valid value for this term.",
          "examples": "`30` (reasonable lower limit on or after 2000-05-01 of a GPS reading under good conditions if the actual precision was not recorded at the time); `100` (reasonable lower limit before 2000-05-01 of a GPS reading under good conditions if the actual precision was not recorded at the time); `71` (uncertainty for a UTM coordinate having 100 meter precision and a known spatial reference system)",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/coordinateUncertaintyInMeters"
        },
        {
          "localName": "country",
          "label": "Country",
          "description": "The name of the country or major administrative unit in which the dcterms:Location occurs.",
          "examples": "`Denmark`; `Colombia`; `España`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/country"
        },
        {
          "localName": "countryCode",
          "label": "Country Code",
          "description": "The standard code for the country in which the dcterms:Location occurs.",
          "examples": "`AR`; `SV`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/countryCode"
        },
        {
          "localName": "county",
          "label": "Second Order Division",
          "description": "The full, unabbreviated name of the next smaller administrative region than stateProvince (county, shire, department, etc.) in which the dcterms:Location occurs.",
          "examples": "`Missoula`; `Los Lagos`; `Mataró`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/county"
        },
        {
          "localName": "decimalLatitude",
          "label": "Decimal Latitude",
          "description": "The geographic latitude (in decimal degrees, using the spatial reference system given in dwc:geodeticDatum) of the geographic center of a dcterms:Location. Positive values are north of the Equator, negative values are south of it. Legal values lie between -90 and 90, inclusive.",
          "examples": "`-41.0983423`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/decimalLatitude"
        },
        {
          "localName": "decimalLongitude",
          "label": "Decimal Longitude",
          "description": "The geographic longitude (in decimal degrees, using the spatial reference system given in dwc:geodeticDatum) of the geographic center of a dcterms:Location. Positive values are east of the Greenwich Meridian, negative values are west of it. Legal values lie between -180 and 180, inclusive.",
          "examples": "`-121.1761111`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/decimalLongitude"
        },
        {
          "localName": "footprintSpatialFit",
          "label": "Footprint Spatial Fit",
          "description": "The ratio of the area of the dwc:footprintWKT to the area of the true (original, or most specific) spatial representation of the dcterms:Location. Legal values are 0, greater than or equal to 1, or undefined. A value of 1 is an exact match or 100% overlap. A value of 0 should be used if the given dwc:footprintWKT does not completely contain the original representation. The dwc:footprintSpatialFit is undefined (and should be left empty) if the original representation is any geometry without area (e.g., a point or polyline) and without uncertainty and the given georeference is not that same geometry (without uncertainty). If both the original and the given georeference are the same point, the dwc:footprintSpatialFit is 1.",
          "examples": "`0`; `1`; `1.5708`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/footprintSpatialFit"
        },
        {
          "localName": "footprintSRS",
          "label": "Footprint SRS",
          "description": "The ellipsoid, geodetic datum, or spatial reference system (SRS) upon which the geometry given in dwc:footprintWKT is based.",
          "examples": "`epsg:4326`; `GEOGCS[\"GCS_WGS_1984\", DATUM[\"D_WGS_1984\", SPHEROID[\"WGS_1984\",6378137,298.257223563]], PRIMEM[\"Greenwich\",0], UNIT[\"Degree\",0.0174532925199433]]` (WKT for the standard WGS84 Spatial Reference System EPSG:4326)",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/footprintSRS"
        },
        {
          "localName": "footprintWKT",
          "label": "Footprint WKT",
          "description": "A Well-Known Text (WKT) representation of the shape (footprint, geometry) that defines the dcterms:Location. A dcterms:Location may have both a point-radius representation (see dwc:decimalLatitude) and a footprint representation, and they may differ from each other.",
          "examples": "`POLYGON ((10 20, 11 20, 11 21, 10 21, 10 20))` (the one-degree bounding box with opposite corners at longitude=10, latitude=20 and longitude=11, latitude=21)",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/footprintWKT"
        },
        {
          "localName": "geodeticDatum",
          "label": "Geodetic Datum",
          "description": "The ellipsoid, geodetic datum, or spatial reference system (SRS) upon which the geographic coordinates given in dwc:decimalLatitude and dwc:decimalLongitude are based.",
          "examples": "`EPSG:4326`; `WGS84`; `NAD27`; `Campo Inchauspe`; `European 1950`; `Clarke 1866`; `unknown`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/geodeticDatum"
        },
        {
          "localName": "georeferencedBy",
          "label": "Georeferenced By",
          "description": "A list (concatenated and separated) of names of people, groups, or organizations who determined the georeference (spatial representation) for the dcterms:Location.",
          "examples": "`Brad Millen (ROM)`; `Kristina Yamamoto | Janet Fang`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/georeferencedBy"
        },
        {
          "localName": "georeferencedDate",
          "label": "Georeferenced Date",
          "description": "The date on which the dcterms:Location was georeferenced.",
          "examples": "`1963-03-08T14:07-0600` (8 Mar 1963 at 2:07pm in the time zone six hours earlier than UTC); `2009-02-20T08:40Z` (20 February 2009 8:40am UTC); `2018-08-29T15:19` (3:19pm local time on 29 August 2018); `1809-02-12` (some time during 12 February 1809); `1906-06` (some time in June 1906); `1971` (some time in the year 1971); `2007-03-01T13:00:00Z/2008-05-11T15:30:00Z` (some time during the interval between 1 March 2007 1pm UTC and 11 May 2008 3:30pm UTC); `1900/1909` (some time during the interval between the beginning of the year 1900 and the end of the year 1909); `2007-11-13/15` (some time in the interval between 13 November 2007 and 15 November 2007)",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/georeferencedDate"
        },
        {
          "localName": "georeferenceProtocol",
          "label": "Georeference Protocol",
          "description": "A description or reference to the methods used to determine the spatial footprint, coordinates, and uncertainties.",
          "examples": "`Georeferencing Quick Reference Guide (Zermoglio et al. 2020, https://doi.org/10.35035/e09p-h128)`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/georeferenceProtocol"
        },
        {
          "localName": "georeferenceRemarks",
          "label": "Georeference Remarks",
          "description": "Notes or comments about the spatial description determination, explaining assumptions made in addition or opposition to the those formalized in the method referred to in dwc:georeferenceProtocol.",
          "examples": "`Assumed distance by road (Hwy. 101)`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/georeferenceRemarks"
        },
        {
          "localName": "georeferenceSources",
          "label": "Georeference Sources",
          "description": "A list (concatenated and separated) of maps, gazetteers, or other resources used to georeference the dcterms:Location, described specifically enough to allow anyone in the future to use the same resources.",
          "examples": "`https://www.geonames.org/`; `USGS 1:24000 Florence Montana Quad 1967 | Terrametrics 2008 on Google Earth`; `GeoLocate`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/georeferenceSources"
        },
        {
          "localName": "higherGeography",
          "label": "Higher Geography",
          "description": "A list (concatenated and separated) of geographic names less specific than the information captured in the dwc:locality term.",
          "examples": "`North Atlantic Ocean`; `South America | Argentina | Patagonia | Parque Nacional Nahuel Huapi | Neuquén | Los Lagos` with accompanying values `South America` (continent) `Argentina` (country), `Neuquén` (first order division), and `Los Lagos` (second order division)",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/higherGeography"
        },
        {
          "localName": "higherGeographyID",
          "label": "Higher Geography ID",
          "description": "An identifier for the geographic region within which the dcterms:Location occurred.",
          "examples": "`http://vocab.getty.edu/tgn/1002002` (Antártida e Islas del Atlántico Sur, Territorio Nacional de la Tierra del Fuego, Argentina).",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/higherGeographyID"
        },
        {
          "localName": "island",
          "label": "Island",
          "description": "The name of the island on or near which the dcterms:Location occurs.",
          "examples": "`Nosy Be`; `Bikini Atoll`; `Vancouver`; `Viti Levu`; `Zanzibar`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/island"
        },
        {
          "localName": "islandGroup",
          "label": "Island Group",
          "description": "The name of the island group in which the dcterms:Location occurs.",
          "examples": "`Alexander Archipelago`; `Archipiélago Diego Ramírez`; `Seychelles`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/islandGroup"
        },
        {
          "localName": "locality",
          "label": "Locality",
          "description": "The specific description of the place.",
          "examples": "`Bariloche, 25 km NNE via Ruta Nacional 40 (=Ruta 237)`; `Queets Rainforest, Olympic National Park`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/locality"
        },
        {
          "localName": "locationAccordingTo",
          "label": "Location According To",
          "description": "Information about the source of this dcterms:Location information. Could be a publication (gazetteer), institution, or team of individuals.",
          "examples": "`Getty Thesaurus of Geographic Names`; `GADM`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/locationAccordingTo"
        },
        {
          "localName": "locationID",
          "label": "Location ID",
          "description": "An identifier for the set of dcterms:Location information. May be a global unique identifier or an identifier specific to the data set.",
          "examples": "`https://opencontext.org/subjects/768A875F-E205-4D0B-DE55-BAB7598D0FD1`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/locationID"
        },
        {
          "localName": "locationRemarks",
          "label": "Location Remarks",
          "description": "Comments or notes about the dcterms:Location.",
          "examples": "`under water since 2005`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/locationRemarks"
        },
        {
          "localName": "maximumDepthInMeters",
          "label": "Maximum Depth In Meters",
          "description": "The greater depth of a range of depth below the local surface, in meters.",
          "examples": "`0`; `200`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/maximumDepthInMeters"
        },
        {
          "localName": "maximumDistanceAboveSurfaceInMeters",
          "label": "Maximum Distance Above Surface In Meters",
          "description": "The greater distance in a range of distance from a reference surface in the vertical direction, in meters. Use positive values for locations above the surface, negative values for locations below. If depth measures are given, the reference surface is the location given by the depth, otherwise the reference surface is the location given by the elevation.",
          "examples": "`-1.5` (below the surface); `4.2` (above the surface); For a 1.5 meter sediment core from the bottom of a lake (at depth 20m) at 300m elevation: verbatimElevation: `300m` minimumElevationInMeters: `300`, maximumElevationInMeters: `300`, verbatimDepth: `20m`, minimumDepthInMeters: `20`, maximumDepthInMeters: `20`, minimumDistanceAboveSurfaceInMeters: `0`, maximumDistanceAboveSurfaceInMeters: `-1.5`.",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/maximumDistanceAboveSurfaceInMeters"
        },
        {
          "localName": "maximumElevationInMeters",
          "label": "Maximum Elevation In Meters",
          "description": "The upper limit of the range of elevation (altitude, usually above sea level), in meters.",
          "examples": "`-205`; `1236`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/maximumElevationInMeters"
        },
        {
          "localName": "minimumDepthInMeters",
          "label": "Minimum Depth In Meters",
          "description": "The lesser depth of a range of depth below the local surface, in meters.",
          "examples": "`0`; `100`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/minimumDepthInMeters"
        },
        {
          "localName": "minimumDistanceAboveSurfaceInMeters",
          "label": "Minimum Distance Above Surface In Meters",
          "description": "The lesser distance in a range of distance from a reference surface in the vertical direction, in meters. Use positive values for locations above the surface, negative values for locations below. If depth measures are given, the reference surface is the location given by the depth, otherwise the reference surface is the location given by the elevation.",
          "examples": "`-1.5` (below the surface); `4.2` (above the surface); For a 1.5 meter sediment core from the bottom of a lake (at depth 20m) at 300m elevation: verbatimElevation: `300m` minimumElevationInMeters: `300`, maximumElevationInMeters: `300`, verbatimDepth: `20m`, minimumDepthInMeters: `20`, maximumDepthInMeters: `20`, minimumDistanceAboveSurfaceInMeters: `0`, maximumDistanceAboveSurfaceInMeters: `-1.5`.",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/minimumDistanceAboveSurfaceInMeters"
        },
        {
          "localName": "minimumElevationInMeters",
          "label": "Minimum Elevation In Meters",
          "description": "The lower limit of the range of elevation (altitude, usually above sea level), in meters.",
          "examples": "`-100`; `802`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/minimumElevationInMeters"
        },
        {
          "localName": "municipality",
          "label": "Third Order Division",
          "description": "The full, unabbreviated name of the next smaller administrative region than county (city, municipality, etc.) in which the dcterms:Location occurs. Do not use this term for a nearby named place that does not contain the actual dcterms:Location.",
          "examples": "`Holzminden`; `Araçatuba`; `Ga-Segonyana`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/municipality"
        },
        {
          "localName": "pointRadiusSpatialFit",
          "label": "Point Radius Spatial Fit",
          "description": "The ratio of the area of the point-radius (dwc:decimalLatitude, dwc:decimalLongitude, dwc:coordinateUncertaintyInMeters) to the area of the true (original, or most specific) spatial representation of the dcterms:Location. Legal values are 0, greater than or equal to 1, or undefined. A value of 1 is an exact match or 100% overlap. A value of 0 should be used if the given point-radius does not completely contain the original representation. The dwc:pointRadiusSpatialFit is undefined (and should be left empty) if the original representation is any geometry without area (e.g., a point or polyline) and without uncertainty and the given georeference is not that same geometry (without uncertainty). If both the original and the given georeference are the same point, the dwc:pointRadiusSpatialFit is 1.",
          "examples": "`0`; `1`; `1.5708`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/pointRadiusSpatialFit"
        },
        {
          "localName": "stateProvince",
          "label": "First Order Division",
          "description": "The name of the next smaller administrative region than country (state, province, canton, department, region, etc.) in which the dcterms:Location occurs.",
          "examples": "`Montana`; `Minas Gerais`; `Córdoba`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/stateProvince"
        },
        {
          "localName": "verbatimCoordinates",
          "label": "Verbatim Coordinates",
          "description": "The verbatim original spatial coordinates of the dcterms:Location. The coordinate ellipsoid, geodeticDatum, or full Spatial Reference System (SRS) for these coordinates should be stored in dwc:verbatimSRS and the coordinate system should be stored in dwc:verbatimCoordinateSystem.",
          "examples": "`41 05 54S 121 05 34W`; `17T 630000 4833400`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimCoordinates"
        },
        {
          "localName": "verbatimCoordinateSystem",
          "label": "Verbatim Coordinate System",
          "description": "The coordinate format for the dwc:verbatimLatitude and dwc:verbatimLongitude or the dwc:verbatimCoordinates of the dcterms:Location.",
          "examples": "`decimal degrees`; `degrees decimal minutes`; `degrees minutes seconds`; `UTM`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimCoordinateSystem"
        },
        {
          "localName": "verbatimDepth",
          "label": "Verbatim Depth",
          "description": "The original description of the depth below the local surface.",
          "examples": "`100-200 m`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimDepth"
        },
        {
          "localName": "verbatimElevation",
          "label": "Verbatim Elevation",
          "description": "The original description of the elevation (altitude, usually above sea level) of the Location.",
          "examples": "`100-200 m`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimElevation"
        },
        {
          "localName": "verbatimLatitude",
          "label": "Verbatim Latitude",
          "description": "The verbatim original latitude of the dcterms:Location. The coordinate ellipsoid, geodeticDatum, or full Spatial Reference System (SRS) for these coordinates should be stored in dwc:verbatimSRS and the coordinate system should be stored in dwc:verbatimCoordinateSystem.",
          "examples": "`41 05 54.03S`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimLatitude"
        },
        {
          "localName": "verbatimLocality",
          "label": "Verbatim Locality",
          "description": "The original textual description of the place.",
          "examples": "`25 km NNE Bariloche por R. Nac. 237`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimLocality"
        },
        {
          "localName": "verbatimLongitude",
          "label": "Verbatim Longitude",
          "description": "The verbatim original longitude of the dcterms:Location. The coordinate ellipsoid, geodeticDatum, or full Spatial Reference System (SRS) for these coordinates should be stored in dwc:verbatimSRS and the coordinate system should be stored in dwc:verbatimCoordinateSystem.",
          "examples": "`121d 10' 34\" W`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimLongitude"
        },
        {
          "localName": "verbatimSRS",
          "label": "Verbatim SRS",
          "description": "The ellipsoid, geodetic datum, or spatial reference system (SRS) upon which coordinates given in dwc:verbatimLatitude and dwc:verbatimLongitude, or dwc:verbatimCoordinates are based.",
          "examples": "`unknown`; `EPSG:4326`; `WGS84`; `NAD27`; `Campo Inchauspe`; `European 1950`; `Clarke 1866`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimSRS"
        },
        {
          "localName": "verticalDatum",
          "label": "Vertical Datum",
          "description": "The vertical datum used as the reference upon which the values in the elevation terms are based.",
          "examples": "`EGM84`; `EGM96`; `EGM2008`; `PGM2000A`; `PGM2004`; `PGM2006`; `PGM2007`; `epsg:7030`; `unknown`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verticalDatum"
        },
        {
          "localName": "waterBody",
          "label": "Water Body",
          "description": "The name of the water body in which the dcterms:Location occurs.",
          "examples": "`Indian Ocean`; `Baltic Sea`; `Hudson River`; `Lago Nahuel Huapi`",
          "organizedIn": "http://purl.org/dc/terms/Location",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/waterBody"
        }
      ],
      "label": "Location",
      "iri": "http://purl.org/dc/terms/Location"
    },
    "GeologicalContext": {
      "name": "GeologicalContext",
      "entries": [
        {
          "localName": "bed",
          "label": "Bed",
          "description": "The full name of the lithostratigraphic bed from which the cataloged item was collected.",
          "examples": "`Harlem coal`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/bed"
        },
        {
          "localName": "earliestAgeOrLowestStage",
          "label": "Earliest Age Or Lowest Stage",
          "description": "The full name of the earliest possible geochronologic age or lowest chronostratigraphic stage attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Atlantic`; `Boreal`; `Skullrockian`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/earliestAgeOrLowestStage"
        },
        {
          "localName": "earliestEonOrLowestEonothem",
          "label": "Earliest Eon Or Lowest Eonothem",
          "description": "The full name of the earliest possible geochronologic eon or lowest chrono-stratigraphic eonothem or the informal name (\"Precambrian\") attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Phanerozoic`; `Proterozoic`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/earliestEonOrLowestEonothem"
        },
        {
          "localName": "earliestEpochOrLowestSeries",
          "label": "Earliest Epoch Or Lowest Series",
          "description": "The full name of the earliest possible geochronologic epoch or lowest chronostratigraphic series attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Holocene`; `Pleistocene`; `Ibexian Series`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/earliestEpochOrLowestSeries"
        },
        {
          "localName": "earliestEraOrLowestErathem",
          "label": "Earliest Era Or Lowest Erathem",
          "description": "The full name of the earliest possible geochronologic era or lowest chronostratigraphic erathem attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Cenozoic`; `Mesozoic`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/earliestEraOrLowestErathem"
        },
        {
          "localName": "earliestPeriodOrLowestSystem",
          "label": "Earliest Period Or Lowest System",
          "description": "The full name of the earliest possible geochronologic period or lowest chronostratigraphic system attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Neogene`; `Tertiary`; `Quaternary`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/earliestPeriodOrLowestSystem"
        },
        {
          "localName": "formation",
          "label": "Formation",
          "description": "The full name of the lithostratigraphic formation from which the cataloged item was collected.",
          "examples": "`Notch Peak Formation`; `House Limestone`; `Fillmore Formation`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/formation"
        },
        {
          "localName": "geologicalContextID",
          "label": "Geological Context ID",
          "description": "An identifier for the set of information associated with a dwc:GeologicalContext (the location within a geological context, such as stratigraphy). May be a global unique identifier or an identifier specific to the data set.",
          "examples": "`https://opencontext.org/subjects/e54377f7-4452-4315-b676-40679b10c4d9`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/geologicalContextID"
        },
        {
          "localName": "group",
          "label": "Group",
          "description": "The full name of the lithostratigraphic group from which the cataloged item was collected.",
          "examples": "`Bathurst`; `Lower Wealden`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/group"
        },
        {
          "localName": "highestBiostratigraphicZone",
          "label": "Highest Biostratigraphic Zone",
          "description": "The full name of the highest possible geological biostratigraphic zone of the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Blancan`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/highestBiostratigraphicZone"
        },
        {
          "localName": "latestAgeOrHighestStage",
          "label": "Latest AgeOr Highest Stage",
          "description": "The full name of the latest possible geochronologic age or highest chronostratigraphic stage attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Atlantic`; `Boreal`; `Skullrockian`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/latestAgeOrHighestStage"
        },
        {
          "localName": "latestEonOrHighestEonothem",
          "label": "Latest Eon Or Highest Eonothem",
          "description": "The full name of the latest possible geochronologic eon or highest chrono-stratigraphic eonothem or the informal name (\"Precambrian\") attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Phanerozoic`; `Proterozoic`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/latestEonOrHighestEonothem"
        },
        {
          "localName": "latestEpochOrHighestSeries",
          "label": "Latest Epoch Or Highest Series",
          "description": "The full name of the latest possible geochronologic epoch or highest chronostratigraphic series attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Holocene`; `Pleistocene`; `Ibexian Series`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/latestEpochOrHighestSeries"
        },
        {
          "localName": "latestEraOrHighestErathem",
          "label": "Latest Era Or Highest Erathem",
          "description": "The full name of the latest possible geochronologic era or highest chronostratigraphic erathem attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Cenozoic`; `Mesozoic`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/latestEraOrHighestErathem"
        },
        {
          "localName": "latestPeriodOrHighestSystem",
          "label": "Latest Period Or Highest System",
          "description": "The full name of the latest possible geochronologic period or highest chronostratigraphic system attributable to the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Neogene`; `Tertiary`; `Quaternary`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/latestPeriodOrHighestSystem"
        },
        {
          "localName": "lithostratigraphicTerms",
          "label": "Lithostratigraphic Terms",
          "description": "The combination of all litho-stratigraphic names for the rock from which the cataloged item was collected.",
          "examples": "`Pleistocene-Weichselien`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/lithostratigraphicTerms"
        },
        {
          "localName": "lowestBiostratigraphicZone",
          "label": "Lowest Biostratigraphic Zone",
          "description": "The full name of the lowest possible geological biostratigraphic zone of the stratigraphic horizon from which the cataloged item was collected.",
          "examples": "`Maastrichtian`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/lowestBiostratigraphicZone"
        },
        {
          "localName": "member",
          "label": "Member",
          "description": "The full name of the lithostratigraphic member from which the cataloged item was collected.",
          "examples": "`Lava Dam Member`; `Hellnmaria Member`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/GeologicalContext",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/member"
        }
      ],
      "label": "Geological Context",
      "iri": "http://rs.tdwg.org/dwc/terms/GeologicalContext"
    },
    "Identification": {
      "name": "Identification",
      "entries": [
        {
          "localName": "dateIdentified",
          "label": "Date Identified",
          "description": "The date on which the subject was determined as representing the dwc:Taxon.",
          "examples": "`1963-03-08T14:07-0600` (8 Mar 1963 at 2:07pm in the time zone six hours earlier than UTC); `2009-02-20T08:40Z` (20 February 2009 8:40am UTC); `2018-08-29T15:19` (3:19pm local time on 29 August 2018); `1809-02-12` (some time during 12 February 1809); `1906-06` (some time in June 1906); `1971` (some time in the year 1971); `2007-03-01T13:00:00Z/2008-05-11T15:30:00Z` (some time during the interval between 1 March 2007 1pm UTC and 11 May 2008 3:30pm UTC); `1900/1909` (some time during the interval between the beginning of the year 1900 and the end of the year 1909); `2007-11-13/15` (some time in the interval between 13 November 2007 and 15 November 2007)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/dateIdentified"
        },
        {
          "localName": "identificationID",
          "label": "Identification ID",
          "description": "An identifier for the dwc:Identification (the body of information associated with the assignment of a scientific name). May be a global unique identifier or an identifier specific to the data set.",
          "examples": "`9992`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/identificationID"
        },
        {
          "localName": "identificationQualifier",
          "label": "Identification Qualifier",
          "description": "A brief phrase or a standard term (\"cf.\", \"aff.\") to express the determiner's doubts about the dwc:Identification.",
          "examples": "`aff. agrifolia var. oxyadenia` (for `Quercus aff. agrifolia var. oxyadenia` with accompanying values `Quercus` in genus, `agrifolia`  in specificEpithet, `oxyadenia`  in infraspecificEpithet, and `var.` in taxonRank); `cf. var. oxyadenia` (for `Quercus agrifolia cf. var. oxyadenia` with accompanying values `Quercus` in genus, `agrifolia` in specificEpithet, `oxyadenia` in infraspecificEpithet, and `var.` in taxonRank)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/identificationQualifier"
        },
        {
          "localName": "identificationReferences",
          "label": "Identification References",
          "description": "A list (concatenated and separated) of references (publication, global unique identifier, URI) used in the dwc:Identification.",
          "examples": "`Aves del Noroeste Patagonico. Christie et al. 2004.`; `Stebbins, R. Field Guide to Western Reptiles and Amphibians. 3rd Edition. 2003. | Irschick, D.J. and Shaffer, H.B. (1997). The polytypic species revisited: Morphological differentiation among tiger salamanders (Ambystoma tigrinum) (Amphibia: Caudata). Herpetologica, 53(1), 30-49.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/identificationReferences"
        },
        {
          "localName": "identificationRemarks",
          "label": "Identification Remarks",
          "description": "Comments or notes about the dwc:Identification.",
          "examples": "`Distinguished between Anthus correndera and Anthus hellmayri based on the comparative lengths of the uñas.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/identificationRemarks"
        },
        {
          "localName": "identificationVerificationStatus",
          "label": "Identification Verification Status",
          "description": "A categorical indicator of the extent to which the taxonomic identification has been verified to be correct.",
          "examples": "`0` (\"unverified\" in HISPID/ABCD).",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/identificationVerificationStatus"
        },
        {
          "localName": "identifiedBy",
          "label": "Identified By",
          "description": "A list (concatenated and separated) of names of people, groups, or organizations who assigned the dwc:Taxon to the subject.",
          "examples": "`James L. Patton`; `Theodore Pappenfuss | Robert Macey`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/identifiedBy"
        },
        {
          "localName": "identifiedByID",
          "label": "Identified By ID",
          "description": "A list (concatenated and separated) of the globally unique identifier for the person, people, groups, or organizations responsible for assigning the dwc:Taxon to the subject.",
          "examples": "`https://orcid.org/0000-0002-1825-0097` (for an individual); `https://orcid.org/0000-0002-1825-0097 | https://orcid.org/0000-0002-1825-0098` (for a list of people)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/identifiedByID"
        },
        {
          "localName": "typeStatus",
          "label": "Type Status",
          "description": "A list (concatenated and separated) of nomenclatural types (type status, typified scientific name, publication) applied to the subject.",
          "examples": "`holotype of Ctenomys sociabilis. Pearson O. P., and M. I. Christie. 1985. Historia Natural, 5(37):388`; `holotype of Pinus abies | holotype of Picea abies`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/typeStatus"
        },
        {
          "localName": "verbatimIdentification",
          "label": "Verbatim Identification",
          "description": "A string representing the taxonomic identification as it appeared in the original record.",
          "examples": "`Peromyscus sp.`; `Ministrymon sp. nov. 1`; `Anser anser × Branta canadensis`; `Pachyporidae?`; `Potentilla × pantotricha Soják`; `Aconitum pilipes × A. variegatum`; `Lepomis auritus x cyanellus`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Identification",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimIdentification"
        }
      ],
      "label": "Identification",
      "iri": "http://rs.tdwg.org/dwc/terms/Identification"
    },
    "Taxon": {
      "name": "Taxon",
      "entries": [
        {
          "localName": "acceptedNameUsage",
          "label": "Accepted Name Usage",
          "description": "The full name, with authorship and date information if known, of the currently valid (zoological) or accepted (botanical) dwc:Taxon.",
          "examples": "`Tamias minimus` (valid name for `Eutamias minimus`)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/acceptedNameUsage"
        },
        {
          "localName": "acceptedNameUsageID",
          "label": "Accepted Name Usage ID",
          "description": "An identifier for the name usage (documented meaning of the name according to a source) of the currently valid (zoological) or accepted (botanical) taxon.",
          "examples": "`tsn:41107` (ITIS); `urn:lsid:ipni.org:names:320035-2` (IPNI); `2704179` (GBIF); `6W3C4` (COL)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/acceptedNameUsageID"
        },
        {
          "localName": "class",
          "label": "Class",
          "description": "The full scientific name of the class in which the dwc:Taxon is classified.",
          "examples": "`Mammalia`; `Hepaticopsida`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/class"
        },
        {
          "localName": "cultivarEpithet",
          "label": "Cultivar Epithet",
          "description": "Part of the name of a cultivar, cultivar group or grex that follows the dwc:scientificName.",
          "examples": "`King Edward` (for scientificName `Solanum tuberosum 'King Edward'` and taxonRank `cultivar`); `Mishmiense` (for scientificName `Rhododendron boothii Mishmiense Group` and taxonRank `cultivar group`); `Atlantis` (for scientificName `Paphiopedilum Atlantis grex` and taxonRank `grex`)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/cultivarEpithet"
        },
        {
          "localName": "family",
          "label": "Family",
          "description": "The full scientific name of the family in which the dwc:Taxon is classified.",
          "examples": "`Felidae`; `Monocleaceae`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/family"
        },
        {
          "localName": "genericName",
          "label": "Generic Name",
          "description": "The genus part of the dwc:scientificName without authorship.",
          "examples": "`Felis` (for scientificName `Felis concolor`, with accompanying values of `Puma concolor` in acceptedNameUsage and `Puma` in genus)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/genericName"
        },
        {
          "localName": "genus",
          "label": "Genus",
          "description": "The full scientific name of the genus in which the dwc:Taxon is classified.",
          "examples": "`Puma`; `Monoclea`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/genus"
        },
        {
          "localName": "higherClassification",
          "label": "Higher Classification",
          "description": "A list (concatenated and separated) of taxa names terminating at the rank immediately superior to the referenced dwc:Taxon.",
          "examples": "`Plantae | Tracheophyta | Magnoliopsida | Ranunculales | Ranunculaceae | Ranunculus`; `Animalia`; `Animalia | Chordata | Vertebrata | Mammalia | Theria | Eutheria | Rodentia | Hystricognatha | Hystricognathi | Ctenomyidae | Ctenomyini | Ctenomys`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/higherClassification"
        },
        {
          "localName": "infragenericEpithet",
          "label": "Infrageneric Epithet",
          "description": "The infrageneric part of a binomial name at ranks above species but below genus.",
          "examples": "`Abacetillus` (for scientificName `Abacetus (Abacetillus) ambiguus`); `Cracca` (for scientificName `Vicia sect. Cracca`)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/infragenericEpithet"
        },
        {
          "localName": "infraspecificEpithet",
          "label": "Infraspecific Epithet",
          "description": "The name of the lowest or terminal infraspecific epithet of the dwc:scientificName, excluding any rank designation.",
          "examples": "`concolor` (for scientificName `Puma concolor concolor (Linnaeus, 1771)`); `oxyadenia` (for scientificName `Quercus agrifolia var. oxyadenia (Torr.) J.T. Howell`); `laxa` (for scientificName `Cheilanthes hirta f. laxa (Kunze) W.Jacobsen & N.Jacobsen`); `scaberrima` (for scientificName `Indigofera charlieriana var. scaberrima (Schinz) J.B.Gillett`)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/infraspecificEpithet"
        },
        {
          "localName": "kingdom",
          "label": "Kingdom",
          "description": "The full scientific name of the kingdom in which the dwc:Taxon is classified.",
          "examples": "`Animalia`; `Archaea`; `Bacteria`; `Chromista`; `Fungi`; `Plantae`; `Protozoa`; `Viruses`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/kingdom"
        },
        {
          "localName": "nameAccordingTo",
          "label": "Name According To",
          "description": "The reference to the source in which the specific taxon concept circumscription is defined or implied - traditionally signified by the Latin \"sensu\" or \"sec.\" (from secundum, meaning \"according to\"). For taxa that result from identifications, a reference to the keys, monographs, experts and other sources should be given.",
          "examples": "`Franz NM, Cardona-Duque J (2013) Description of two new species and phylogenetic reassessment of Perelleschus Wibmer & O’Brien, 1986 (Coleoptera: Curculionidae), with a complete taxonomic concept history of Perelleschus sec. Franz & Cardona-Duque, 2013. Syst Biodivers. 11: 209–236.` (as the full citation of the Franz & Cardona-Duque (2013) in Perelleschus splendida sec. Franz & Cardona-Duque (2013))",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/nameAccordingTo"
        },
        {
          "localName": "nameAccordingToID",
          "label": "Name According To ID",
          "description": "An identifier for the source in which the specific taxon concept circumscription is defined or implied. See dwc:nameAccordingTo.",
          "examples": "`https://doi.org/10.1016/S0269-915X(97)80026-2`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/nameAccordingToID"
        },
        {
          "localName": "namePublishedIn",
          "label": "Name Published In",
          "description": "A reference for the publication in which the dwc:scientificName was originally established under the rules of the associated dwc:nomenclaturalCode.",
          "examples": "`Pearson O. P., and M. I. Christie. 1985. Historia Natural, 5(37):388`; `Forel, Auguste, Diagnosies provisoires de quelques espèces nouvelles de fourmis de Madagascar, récoltées par M. Grandidier., Annales de la Societe Entomologique de Belgique, Comptes-rendus des Seances 30, 1886`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/namePublishedIn"
        },
        {
          "localName": "namePublishedInID",
          "label": "Name Published In ID",
          "description": "An identifier for the publication in which the dwc:scientificName was originally established under the rules of the associated dwc:nomenclaturalCode.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/namePublishedInID"
        },
        {
          "localName": "namePublishedInYear",
          "label": "Name Published In Year",
          "description": "The four-digit year in which the dwc:scientificName was published.",
          "examples": "`1915`; `2008`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/namePublishedInYear"
        },
        {
          "localName": "nomenclaturalCode",
          "label": "Nomenclatural Code",
          "description": "The nomenclatural code (or codes in the case of an ambiregnal name) under which the dwc:scientificName is constructed.",
          "examples": "`ICN`; `ICZN`; `BC`; `ICNCP`; `BioCode`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/nomenclaturalCode"
        },
        {
          "localName": "nomenclaturalStatus",
          "label": "Nomenclatural Status",
          "description": "The status related to the original publication of the name and its conformance to the relevant rules of nomenclature. It is based essentially on an algorithm according to the business rules of the code. It requires no taxonomic opinion.",
          "examples": "`nom. ambig.`; `nom. illeg.`; `nom. subnud.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/nomenclaturalStatus"
        },
        {
          "localName": "order",
          "label": "Order",
          "description": "The full scientific name of the order in which the dwc:Taxon is classified.",
          "examples": "`Carnivora`; `Monocleales`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/order"
        },
        {
          "localName": "originalNameUsage",
          "label": "Original Name Usage",
          "description": "The taxon name, with authorship and date information if known, as it originally appeared when first established under the rules of the associated dwc:nomenclaturalCode. The basionym (botany) or basonym (bacteriology) of the dwc:scientificName or the senior/earlier homonym for replaced names.",
          "examples": "`Pinus abies`; `Gasterosteus saltatrix Linnaeus 1768`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/originalNameUsage"
        },
        {
          "localName": "originalNameUsageID",
          "label": "Original Name Usage ID",
          "description": "An identifier for the name usage (documented meaning of the name according to a source) in which the terminal element of the dwc:scientificName was originally established under the rules of the associated dwc:nomenclaturalCode.",
          "examples": "`tsn:41107` (ITIS); `urn:lsid:ipni.org:names:320035-2` (IPNI); `2704179` (GBIF); `6W3C4` (COL)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/originalNameUsageID"
        },
        {
          "localName": "parentNameUsage",
          "label": "Parent Name Usage",
          "description": "The full name, with authorship and date information if known, of the direct, most proximate higher-rank parent dwc:Taxon (in a classification) of the most specific element of the dwc:scientificName.",
          "examples": "`Rubiaceae`; `Gruiformes`; `Testudinae`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/parentNameUsage"
        },
        {
          "localName": "parentNameUsageID",
          "label": "Parent Name Usage ID",
          "description": "An identifier for the name usage (documented meaning of the name according to a source) of the direct, most proximate higher-rank parent taxon (in a classification) of the most specific element of the dwc:scientificName.",
          "examples": "`tsn:41074` (ITIS); `urn:lsid:ipni.org:names:30001404-2` (IPNI); `2704173` (GBIF); `6T8N` (COL)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/parentNameUsageID"
        },
        {
          "localName": "phylum",
          "label": "Phylum",
          "description": "The full scientific name of the phylum or division in which the dwc:Taxon is classified.",
          "examples": "`Chordata` (phylum); `Bryophyta` (division)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/phylum"
        },
        {
          "localName": "scientificName",
          "label": "Scientific Name",
          "description": "The full scientific name, with authorship and date information if known. When forming part of a dwc:Identification, this should be the name in lowest level taxonomic rank that can be determined. This term should not contain identification qualifications, which should instead be supplied in the dwc:identificationQualifier term.",
          "examples": "`Coleoptera` (order); `Vespertilionidae` (family); `Manis` (genus); `Ctenomys sociabilis` (genus + specificEpithet); `Ambystoma tigrinum diaboli` (genus + specificEpithet + infraspecificEpithet); `Roptrocerus typographi (Györfi, 1952)` (genus + specificEpithet + scientificNameAuthorship); `Quercus agrifolia var. oxyadenia (Torr.) J.T. Howell` (genus + specificEpithet + taxonRank + infraspecificEpithet + scientificNameAuthorship); `×Agropogon littoralis (Sm.) C. E. Hubb.`; `Mentha ×smithiana R. A. Graham`; `Agrostis stolonifera L. × Polypogon monspeliensis (L.) Desf.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/scientificName"
        },
        {
          "localName": "scientificNameAuthorship",
          "label": "Scientific Name Authorship",
          "description": "The authorship information for the dwc:scientificName formatted according to the conventions of the applicable dwc:nomenclaturalCode.",
          "examples": "`(Torr.) J.T. Howell`; `(Martinovský) Tzvelev`; `(Györfi, 1952)`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/scientificNameAuthorship"
        },
        {
          "localName": "scientificNameID",
          "label": "Scientific Name ID",
          "description": "An identifier for the nomenclatural (not taxonomic) details of a scientific name.",
          "examples": "`urn:lsid:ipni.org:names:37829-1:1.3`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/scientificNameID"
        },
        {
          "localName": "specificEpithet",
          "label": "Specific Epithet",
          "description": "The name of the first or species epithet of the dwc:scientificName.",
          "examples": "`concolor`; `gottschei`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/specificEpithet"
        },
        {
          "localName": "subfamily",
          "label": "Subfamily",
          "description": "The full scientific name of the subfamily in which the dwc:Taxon is classified.",
          "examples": "`Periptyctinae`; `Orchidoideae`; `Sphindociinae`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/subfamily"
        },
        {
          "localName": "subgenus",
          "label": "Subgenus",
          "description": "The full scientific name of the subgenus in which the dwc:Taxon is classified. Values should include the genus to avoid homonym confusion.",
          "examples": "`Strobus`; `Amerigo`; `Pilosella`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/subgenus"
        },
        {
          "localName": "subtribe",
          "label": "Subtribe",
          "description": "The full scientific name of the subtribe in which the dwc:Taxon is classified.",
          "examples": "`Plotinini`; `Typhaeini`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/subtribe"
        },
        {
          "localName": "superfamily",
          "label": "Superfamily",
          "description": "The full scientific name of the superfamily in which the dwc:Taxon is classified.",
          "examples": "`Achatinoidea`; `Cerithioidea`; `Helicoidea`; `Hypsibioidea`; `Valvatoidea`; `Zonitoidea`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/superfamily"
        },
        {
          "localName": "taxonConceptID",
          "label": "Taxon Concept ID",
          "description": "An identifier for the taxonomic concept to which the record refers - not for the nomenclatural details of a dwc:Taxon.",
          "examples": "`8fa58e08-08de-4ac1-b69c-1235340b7001`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/taxonConceptID"
        },
        {
          "localName": "taxonID",
          "label": "Taxon ID",
          "description": "An identifier for the set of dwc:Taxon information. May be a global unique identifier or an identifier specific to the data set.",
          "examples": "`8fa58e08-08de-4ac1-b69c-1235340b7001`; `32567`; `https://www.gbif.org/species/212`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/taxonID"
        },
        {
          "localName": "taxonomicStatus",
          "label": "Taxonomic Status",
          "description": "The status of the use of the dwc:scientificName as a label for a taxon. Requires taxonomic opinion to define the scope of a dwc:Taxon. Rules of priority then are used to define the taxonomic status of the nomenclature contained in that scope, combined with the experts opinion. It must be linked to a specific taxonomic reference that defines the concept.",
          "examples": "`invalid`; `misapplied`; `homotypic synonym`; `accepted`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/taxonomicStatus"
        },
        {
          "localName": "taxonRank",
          "label": "Taxon Rank",
          "description": "The taxonomic rank of the most specific name in the dwc:scientificName.",
          "examples": "`subspecies`; `varietas`; `forma`; `species`; `genus`; `nothogenus`; `nothospecies`; `nothosubspecies`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/taxonRank"
        },
        {
          "localName": "taxonRemarks",
          "label": "Taxon Remarks",
          "description": "Comments or notes about the taxon or name.",
          "examples": "`this name is a misspelling in common use`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/taxonRemarks"
        },
        {
          "localName": "tribe",
          "label": "Tribe",
          "description": "The full scientific name of the tribe in which the dwc:Taxon is classified.",
          "examples": "`Ortaliini`; `Arethuseae`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/tribe"
        },
        {
          "localName": "verbatimTaxonRank",
          "label": "Verbatim Taxon Rank",
          "description": "The taxonomic rank of the most specific name in the dwc:scientificName as it appears in the original record.",
          "examples": "`Agamospecies`; `sub-lesus`; `prole`; `apomict`; `nothogrex`; `sp.`; `subsp.`; `var.`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/verbatimTaxonRank"
        },
        {
          "localName": "vernacularName",
          "label": "Vernacular Name",
          "description": "A common or vernacular name.",
          "examples": "`Andean Condor`; `Condor Andino`; `American Eagle`; `Gänsegeier`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/Taxon",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/vernacularName"
        }
      ],
      "label": "Taxon",
      "iri": "http://rs.tdwg.org/dwc/terms/Taxon"
    },
    "ResourceRelationship": {
      "name": "ResourceRelationship",
      "entries": [
        {
          "localName": "relatedResourceID",
          "label": "Related Resource ID",
          "description": "An identifier for a related resource (the object, rather than the subject of the relationship).",
          "examples": "`dc609808-b09b-11e8-96f8-529269fb1459`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/relatedResourceID"
        },
        {
          "localName": "relationshipAccordingTo",
          "label": "Relationship According To",
          "description": "The source (person, organization, publication, reference) establishing the relationship between the two resources.",
          "examples": "`Julie Woodruff`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/relationshipAccordingTo"
        },
        {
          "localName": "relationshipEstablishedDate",
          "label": "Relationship Established Date",
          "description": "The date-time on which the relationship between the two resources was established.",
          "examples": "`1963-03-08T14:07-0600` (8 Mar 1963 at 2:07pm in the time zone six hours earlier than UTC); `2009-02-20T08:40Z` (20 February 2009 8:40am UTC); `2018-08-29T15:19` (3:19pm local time on 29 August 2018); `1809-02-12` (some time during 12 February 1809); `1906-06` (some time in June 1906); `1971` (some time in the year 1971); `2007-03-01T13:00:00Z/2008-05-11T15:30:00Z` (some time during the interval between 1 March 2007 1pm UTC and 11 May 2008 3:30pm UTC); `1900/1909` (some time during the interval between the beginning of the year 1900 and the end of the year 1909); `2007-11-13/15` (some time in the interval between 13 November 2007 and 15 November 2007)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/relationshipEstablishedDate"
        },
        {
          "localName": "relationshipOfResource",
          "label": "Relationship Of Resource",
          "description": "The relationship of the subject (identified by dwc:resourceID) to the object (identified by dwc:relatedResourceID).",
          "examples": "`same as`; `duplicate of`; `mother of`; `offspring of`; `sibling of`; `parasite of`; `host of`; `valid synonym of`; `located within`; `pollinator of members of taxon`; `pollinated specific plant`; `pollinated by members of taxon`; `on slab with`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/relationshipOfResource"
        },
        {
          "localName": "relationshipOfResourceID",
          "label": "Relationship Of Resource ID",
          "description": "An identifier for the relationship type (predicate) that connects the subject identified by dwc:resourceID to its object identified by dwc:relatedResourceID.",
          "examples": "`http://purl.obolibrary.org/obo/RO_0002456` (for the relation `pollinated by`); `http://purl.obolibrary.org/obo/RO_0002455` (for the relation `pollinates`); `https://www.inaturalist.org/observation_fields/879` (for the relation `eaten by`)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/relationshipOfResourceID"
        },
        {
          "localName": "relationshipRemarks",
          "label": "Relationship Remarks",
          "description": "Comments or notes about the relationship between the two resources.",
          "examples": "`mother and offspring collected from the same nest`; `pollinator captured in the act`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/relationshipRemarks"
        },
        {
          "localName": "resourceID",
          "label": "Resource ID",
          "description": "An identifier for the resource that is the subject of the relationship.",
          "examples": "`f809b9e0-b09b-11e8-96f8-529269fb1459`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/resourceID"
        },
        {
          "localName": "resourceRelationshipID",
          "label": "Resource Relationship ID",
          "description": "An identifier for an instance of relationship between one resource (the subject) and another (dwc:relatedResource, the object).",
          "examples": "`04b16710-b09c-11e8-96f8-529269fb1459`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/ResourceRelationship",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/resourceRelationshipID"
        }
      ],
      "label": "Resource Relationship",
      "iri": "http://rs.tdwg.org/dwc/terms/ResourceRelationship"
    },
    "MeasurementOrFact": {
      "name": "MeasurementOrFact",
      "entries": [
        {
          "localName": "measurementAccuracy",
          "label": "Measurement Accuracy",
          "description": "The description of the potential error associated with the dwc:measurementValue.",
          "examples": "`0.01`; `normal distribution with variation of 2 m`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementAccuracy"
        },
        {
          "localName": "measurementDeterminedBy",
          "label": "Measurement Determined By",
          "description": "A list (concatenated and separated) of names of people, groups, or organizations who determined the value of the dwc:MeasurementOrFact.",
          "examples": "`Rob Guralnick`; `Peter Desmet | Stijn Van Hoey`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementDeterminedBy"
        },
        {
          "localName": "measurementDeterminedDate",
          "label": "Measurement Determined Date",
          "description": "The date on which the dwc:MeasurementOrFact was made.",
          "examples": "`1963-03-08T14:07-0600` (8 Mar 1963 at 2:07pm in the time zone six hours earlier than UTC); `2009-02-20T08:40Z` (20 February 2009 8:40am UTC); `2018-08-29T15:19` (3:19pm local time on 29 August 2018); `1809-02-12` (some time during 12 February 1809); `1906-06` (some time in June 1906); `1971` (some time in the year 1971); `2007-03-01T13:00:00Z/2008-05-11T15:30:00Z` (some time during the interval between 1 March 2007 1pm UTC and 11 May 2008 3:30pm UTC); `1900/1909` (some time during the interval between the beginning of the year 1900 and the end of the year 1909); `2007-11-13/15` (some time in the interval between 13 November 2007 and 15 November 2007)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementDeterminedDate"
        },
        {
          "localName": "measurementID",
          "label": "Measurement ID",
          "description": "An identifier for the dwc:MeasurementOrFact (information pertaining to measurements, facts, characteristics, or assertions). May be a global unique identifier or an identifier specific to the data set.",
          "examples": "`9c752d22-b09a-11e8-96f8-529269fb1459`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementID"
        },
        {
          "localName": "measurementMethod",
          "label": "Measurement Method",
          "description": "A description of or reference to (publication, URI) the method or protocol used to determine the measurement, fact, characteristic, or assertion.",
          "examples": "`minimum convex polygon around burrow entrances` (for a home range area); `barometric altimeter` (for an elevation)",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementMethod"
        },
        {
          "localName": "measurementRemarks",
          "label": "Measurement Remarks",
          "description": "Comments or notes accompanying the dwc:MeasurementOrFact.",
          "examples": "`tip of tail missing`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementRemarks"
        },
        {
          "localName": "measurementType",
          "label": "Measurement Type",
          "description": "The nature of the measurement, fact, characteristic, or assertion.",
          "examples": "`tail length`; `temperature`; `trap line length`; `survey area`; `trap type`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementType"
        },
        {
          "localName": "measurementUnit",
          "label": "Measurement Unit",
          "description": "The units associated with the dwc:measurementValue.",
          "examples": "`m`; `g`; `l`; `°C`; `mm`; `km²`; `%`; `hh:mm:ss`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementUnit"
        },
        {
          "localName": "measurementValue",
          "label": "Measurement Value",
          "description": "The value of the measurement, fact, characteristic, or assertion.",
          "examples": "`45`; `20`; `1`; `14.5`; `UV-light`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/measurementValue"
        },
        {
          "localName": "parentMeasurementID",
          "label": "Parent Measurement ID",
          "description": "An identifier for a broader dwc:MeasurementOrFact that groups this and potentially other dwc:MeasurementOrFacts.",
          "examples": "`9c752d22-b09a-11e8-96f8-529269fb1459`; `E1_E1_O1_M1`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/terms/parentMeasurementID"
        }
      ],
      "label": "Measurement Or Fact",
      "iri": "http://rs.tdwg.org/dwc/terms/MeasurementOrFact"
    },
    "UseWithIRI": {
      "name": "UseWithIRI",
      "entries": [
        {
          "localName": "behavior",
          "label": "Behavior (IRI)",
          "description": "A description of the behavior shown by the subject at the time the dwc:Occurrence was recorded.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/behavior"
        },
        {
          "localName": "caste",
          "label": "Caste (IRI)",
          "description": "Categorisation of individuals for eusocial species (including some mammals and arthropods).",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/caste"
        },
        {
          "localName": "dataGeneralizations",
          "label": "Data Generalizations (IRI)",
          "description": "Actions taken to make the shared data less specific or complete than in its original form. Suggests that alternative data of higher quality may be available on request.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/dataGeneralizations"
        },
        {
          "localName": "degreeOfEstablishment",
          "label": "Degree of Establishment (IRI)",
          "description": "The degree to which a dwc:Organism survives, reproduces, and expands its range at the given place and time.",
          "examples": "`http://rs.tdwg.org/dwcdoe/values/d003`; `http://rs.tdwg.org/dwcdoe/values/d005`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/degreeOfEstablishment"
        },
        {
          "localName": "disposition",
          "label": "Disposition (IRI)",
          "description": "The current state of a specimen with respect to the collection identified in dwc:collectionCode or dwc:collectionID.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/disposition"
        },
        {
          "localName": "earliestGeochronologicalEra",
          "label": "Earliest Geochronological Era",
          "description": "Use to link a dwc:GeologicalContext instance to chronostratigraphic time periods at the lowest possible level in a standardized hierarchy.   Use this property to point to the earliest possible geological time period from which the cataloged item was collected.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/earliestGeochronologicalEra"
        },
        {
          "localName": "establishmentMeans",
          "label": "Establishment Means (IRI)",
          "description": "Statement about whether a dwc:Organism has been introduced to a given place and time through the direct or indirect activity of modern humans.",
          "examples": "`http://rs.tdwg.org/dwcem/values/e001`; `http://rs.tdwg.org/dwcem/values/e005`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/establishmentMeans"
        },
        {
          "localName": "eventType",
          "label": "Event Type (IRI)",
          "description": "The nature of the dwc:Event.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/eventType"
        },
        {
          "localName": "fieldNotes",
          "label": "Field Notes (IRI)",
          "description": "One of a) an indicator of the existence of, b) a reference to (publication, URI), or c) the text of notes taken in the field about the dwc:Event.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/fieldNotes"
        },
        {
          "localName": "fieldNumber",
          "label": "Field Number (IRI)",
          "description": "An identifier given to the event in the field. Often serves as a link between field notes and the dwc:Event.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/fieldNumber"
        },
        {
          "localName": "footprintSRS",
          "label": "Footprint SRS (IRI)",
          "description": "The ellipsoid, geodetic datum, or spatial reference system (SRS) upon which the geometry given in dwc:footprintWKT is based.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/footprintSRS"
        },
        {
          "localName": "footprintWKT",
          "label": "Footprint WKT (IRI)",
          "description": "A Well-Known Text (WKT) representation of the shape (footprint, geometry) that defines the dcterms:Location. A dcterms:Location may have both a point-radius representation (see dwc:decimalLatitude) and a footprint representation, and they may differ from each other.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/footprintWKT"
        },
        {
          "localName": "fromLithostratigraphicUnit",
          "label": "From Lithostratigraphic Unit",
          "description": "Use to link a dwc:GeologicalContext instance to an IRI-identified lithostratigraphic unit at the lowest possible level in a hierarchy.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/fromLithostratigraphicUnit"
        },
        {
          "localName": "geodeticDatum",
          "label": "Geodetic Datum (IRI)",
          "description": "The ellipsoid, geodetic datum, or spatial reference system (SRS) upon which the geographic coordinates given in dwc:decimalLatitude and dwc:decimalLongitude is based.",
          "examples": "`https://epsg.io/4326`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/geodeticDatum"
        },
        {
          "localName": "georeferencedBy",
          "label": "Georeferenced By (IRI)",
          "description": "A person, group, or organization who determined the georeference (spatial representation) for the dcterms:Location.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/georeferencedBy"
        },
        {
          "localName": "georeferenceProtocol",
          "label": "Georeference Protocol (IRI)",
          "description": "A description or reference to the methods used to determine the spatial footprint, coordinates, and uncertainties.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/georeferenceProtocol"
        },
        {
          "localName": "georeferenceSources",
          "label": "Georeference Sources (IRI)",
          "description": "A map, gazetteer, or other resource used to georeference the dcterms:Location.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/georeferenceSources"
        },
        {
          "localName": "georeferenceVerificationStatus",
          "label": "Georeference Verification Status (IRI)",
          "description": "A categorical description of the extent to which the georeference has been verified to represent the best possible spatial description for the dcterms:Location of the dwc:Occurrence.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/georeferenceVerificationStatus"
        },
        {
          "localName": "habitat",
          "label": "Habitat (IRI)",
          "description": "A category or description of the habitat in which the dwc:Event occurred.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/habitat"
        },
        {
          "localName": "identificationQualifier",
          "label": "Identification Qualifier (IRI)",
          "description": "A controlled value to express the determiner's doubts about the dwc:Identification.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/identificationQualifier"
        },
        {
          "localName": "identificationVerificationStatus",
          "label": "Identification Verification Status (IRI)",
          "description": "A categorical indicator of the extent to which the taxonomic identification has been verified to be correct.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/identificationVerificationStatus"
        },
        {
          "localName": "identifiedBy",
          "label": "Identified By (IRI)",
          "description": "A person, group, or organization who assigned the dwc:Taxon to the subject.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/identifiedBy"
        },
        {
          "localName": "inCollection",
          "label": "In Collection",
          "description": "Use to link any subject resource that is part of a collection to the collection containing the resource.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/inCollection"
        },
        {
          "localName": "inDataset",
          "label": "In Dataset",
          "description": "Use to link a subject dataset record to the dataset which contains it.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/inDataset"
        },
        {
          "localName": "inDescribedPlace",
          "label": "In Described Place",
          "description": "Use to link a dcterms:Location instance subject to the lowest level standardized hierarchically-described resource.",
          "examples": "`http://vocab.getty.edu/tgn/1019987`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/inDescribedPlace"
        },
        {
          "localName": "informationWithheld",
          "label": "Information Withheld (IRI)",
          "description": "Additional information that exists, but that has not been shared in the given record.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/informationWithheld"
        },
        {
          "localName": "latestGeochronologicalEra",
          "label": "Latest Geochronological Era",
          "description": "Use to link a dwc:GeologicalContext instance to chronostratigraphic time periods at the lowest possible level in a standardized hierarchy.   Use this property to point to the latest possible geological time period from which the cataloged item was collected.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/latestGeochronologicalEra"
        },
        {
          "localName": "lifeStage",
          "label": "Life Stage (IRI)",
          "description": "The age class or life stage of the dwc:Organism(s) at the time the dwc:Occurrence was recorded.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/lifeStage"
        },
        {
          "localName": "locationAccordingTo",
          "label": "Location According To (IRI)",
          "description": "Information about the source of this dcterms:Location information. Could be a publication (gazetteer), institution, or team of individuals.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/locationAccordingTo"
        },
        {
          "localName": "measurementDeterminedBy",
          "label": "Measurement Determined By (IRI)",
          "description": "A person, group, or organization who determined the value of the dwc:MeasurementOrFact.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/measurementDeterminedBy"
        },
        {
          "localName": "measurementMethod",
          "label": "Measurement Method (IRI)",
          "description": "The method or protocol used to determine the measurement, fact, characteristic, or assertion.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/measurementMethod"
        },
        {
          "localName": "measurementType",
          "label": "Measurement Type (IRI)",
          "description": "The nature of the measurement, fact, characteristic, or assertion.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/measurementType"
        },
        {
          "localName": "measurementUnit",
          "label": "Measurement Unit (IRI)",
          "description": "The units associated with the dwc:measurementValue.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/measurementUnit"
        },
        {
          "localName": "measurementValue",
          "label": "Measurement Value (IRI)",
          "description": "The value of the measurement, fact, characteristic, or assertion.",
          "examples": "`http://vocab.nerc.ac.uk/collection/L22/current/TOOL0960/`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/measurementValue"
        },
        {
          "localName": "occurrenceStatus",
          "label": "Occurrence Status (IRI)",
          "description": "A statement about the presence or absence of a dwc:Taxon at a dcterms:Location.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/occurrenceStatus"
        },
        {
          "localName": "organismQuantityType",
          "label": "Organism Quantity Type (IRI)",
          "description": "The type of quantification system used for the quantity of organisms.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/organismQuantityType"
        },
        {
          "localName": "pathway",
          "label": "Pathway (IRI)",
          "description": "The process by which a dwc:Organism came to be in a given place at a given time.",
          "examples": "`http://rs.tdwg.org/dwcpw/values/p002`; `http://rs.tdwg.org/dwcpw/values/p046`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/pathway"
        },
        {
          "localName": "preparations",
          "label": "Preparations (IRI)",
          "description": "A preparation or preservation method for a specimen.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/preparations"
        },
        {
          "localName": "recordedBy",
          "label": "Recorded By (IRI)",
          "description": "A person, group, or organization responsible for recording the original dwc:Occurrence.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/recordedBy"
        },
        {
          "localName": "recordNumber",
          "label": "Record Number (IRI)",
          "description": "An identifier given to the dwc:Occurrence at the time it was recorded. Often serves as a link between field notes and a dwc:Occurrence record, such as a specimen collector's number.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/recordNumber"
        },
        {
          "localName": "reproductiveCondition",
          "label": "Reproductive Condition (IRI)",
          "description": "The reproductive condition of the biological individual(s) represented in the dwc:Occurrence.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/reproductiveCondition"
        },
        {
          "localName": "sampleSizeUnit",
          "label": "Sampling Size Unit (IRI)",
          "description": "The unit of measurement of the size (time duration, length, area, or volume) of a sample in a sampling dwc:Event.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/sampleSizeUnit"
        },
        {
          "localName": "samplingProtocol",
          "label": "Sampling Protocol (IRI)",
          "description": "The methods or protocols used during a dwc:Event, denoted by an IRI.",
          "examples": "`https://doi.org/10.1111/j.1466-8238.2009.00467.x`",
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/samplingProtocol"
        },
        {
          "localName": "sex",
          "label": "Sex (IRI)",
          "description": "The sex of the biological individual(s) represented in the dwc:Occurrence.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/sex"
        },
        {
          "localName": "toTaxon",
          "label": "To Taxon",
          "description": "Use to link a dwc:Identification instance subject to a taxonomic entity such as a taxon, taxon concept, or taxon name use.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/toTaxon"
        },
        {
          "localName": "typeStatus",
          "label": "Type Status (IRI)",
          "description": "A nomenclatural type (type status, typified scientific name, publication) applied to the subject.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/typeStatus"
        },
        {
          "localName": "verbatimCoordinateSystem",
          "label": "Verbatim Coordinate System (IRI)",
          "description": "The spatial coordinate system for the dwc:verbatimLatitude and dwc:verbatimLongitude or the dwc:verbatimCoordinates of the dcterms:Location.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/verbatimCoordinateSystem"
        },
        {
          "localName": "verbatimSRS",
          "label": "Verbatim SRS (IRI)",
          "description": "The ellipsoid, geodetic datum, or spatial reference system (SRS) upon which coordinates given in dwc:verbatimLatitude and dwc:verbatimLongitude, or dwc:verbatimCoordinates are based.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/verbatimSRS"
        },
        {
          "localName": "verticalDatum",
          "label": "Vertical Datum (IRI)",
          "description": "The vertical datum used as the reference upon which the values in the elevation terms are based.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/verticalDatum"
        },
        {
          "localName": "vitality",
          "label": "Vitality (IRI)",
          "description": "An indication of whether a dwc:Organism was alive or dead at the time of collection or observation.",
          "examples": null,
          "organizedIn": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI",
          "status": "recommended",
          "iri": "http://rs.tdwg.org/dwc/iri/vitality"
        }
      ],
      "label": "Use With IRI",
      "iri": "http://rs.tdwg.org/dwc/terms/attributes/UseWithIRI"
    }
  },
  "typeStatuses": [
    "Holotype",
    "Paratype",
    "Syntype",
    "Hapantotype",
    "Neotype",
    "Lectotype",
    "Paralectotype",
    "Allotype",
    "Alloneotype",
    "Allolectotype",
    "Other material"
  ]
}
