import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Compiler,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Inject,
  NgModule,
  NO_ERRORS_SCHEMA,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { basicSetup, EditorState, EditorView } from '@codemirror/basic-setup';
import { html } from '@codemirror/lang-html';
import { YdocService } from '@app/editor/services/ydoc.service';
import { schema } from '@app/editor/utils/Schema';
import { DOMParser } from 'prosemirror-model';
import { uuidv4 } from 'lib0/random';
import { ProsemirrorEditorsService } from '@app/editor/services/prosemirror-editors.service';
import { citationElementMap } from '@app/editor/services/citable-elements.service';
import { supplementaryFileJSON } from '@app/editor/utils/section-templates/form-io-json/supplementaryFileFormIOJson';
import { supplementaryFile } from '@app/editor/utils/interfaces/supplementaryFile';
import { filterFieldsValues } from '@app/editor/utils/fieldsMenusAndScemasFns';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { BrowserModule } from '@angular/platform-browser';
import { FormControlNameDirective } from '@app/editor/directives/form-control-name.directive';
import { MatFormioModule } from '@app/formio-angular-material/angular-material-formio.module';
import { SafePipe } from '@app/formio-angular-material/components/MaterialComponent';
import { MaterialModule } from '@app/shared/material.module';
import { FormioModule } from '@formio/angular';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneComponent } from '@app/editor/dropzone/dropzone.component'

@Component({
  selector: 'app-add-supplementary-file',
  templateUrl: './add-supplementary-file.component.html',
  styleUrls: ['./add-supplementary-file.component.scss']
})
export class AddSupplementaryFileComponent implements AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;

  initFunc: (selfRef: any) => () => void;
  renderCodemMirrorEditors: (selfRef: any) => (supplementaryFileID: string) => string;
  onChange: (selfRef: any) => (change: any) => void;
  submitSupplementaryFile: (selfRef: any) => () => void;
  onSubmit: (selfRef: any) => (submission?: any) => void;
  fileIsUploaded: (selfRef: any) => (uploaded: any) => void;
  uploadedFileInCDN: (selfRef: any) => (fileData: any) => void;

  defaultFileIsUploaded: (selfRef: any) => (uploaded: any) => void;
  defaultSubmitSupplementaryFile: () => void;
  defaultOnChange: (change: any) => void;
  defaultOnSubmit: (submission?: any) => void;

  renderForm = false;
  hidehtml = true;
  sectionContent = JSON.parse(JSON.stringify(supplementaryFileJSON));
  codemirrorHTMLEditor?: EditorView
  codemirrorHtmlTemplate?: HTMLElement;
  supplementaryFilesTemplatesObj: any
  fileLinkControl = new UntypedFormControl('',Validators.required);
  fileType = null

  section = { mode: 'editMode' };
  sectionForm = new UntypedFormGroup({});
  supplementaryFileID?: string;
  modalTemplate: string;

  isValid:boolean = true;
  formIoSubmission: any;
  formIoRoot: any;

  constructor(
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    public dialog: MatDialog,
    private compiler: Compiler,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddSupplementaryFileComponent>,
    private ydocService: YdocService,
    private serviceShare: ServiceShare,
    @Inject(APP_CONFIG) readonly config: AppConfig,
    @Inject(MAT_DIALOG_DATA) public data:  { supplementaryFile:supplementaryFile, updateOnSave: boolean, index: number, supplementaryFileID: string }
  ) {
    this.modalTemplate = this.ydocService.supplementaryFilesMap.get("supplementaryFilesModalTemplate") as string;
    const self = this;
    this.initFunc = (selfRef: this) => () => {
      selfRef.codemirrorHtmlTemplate = document.querySelector(".codemirrorHtmlTemplate");
      let supplementaryFilesInitialFormIOJson = self.ydocService.supplementaryFilesMap!.get('supplementaryFilesInitialFormIOJson');
    if(supplementaryFilesInitialFormIOJson){
      selfRef.sectionContent = JSON.parse(JSON.stringify(supplementaryFilesInitialFormIOJson));
    }
    try {
      selfRef.supplementaryFileID = selfRef.data.supplementaryFileID || uuidv4();
      selfRef.sectionContent.props = {isCitableElement:true}
      self.serviceShare.FormBuilderService.setAutoFocusInSchema(selfRef.sectionContent);

      let supplementaryFileHTML = selfRef.renderCodemMirrorEditors(selfRef)(selfRef.supplementaryFileID!)
      if (selfRef.data.supplementaryFile) {
        let titleContainer = document.createElement('div');
        titleContainer.innerHTML = selfRef.data.supplementaryFile.title;
        let authorsContainer = document.createElement('div');
        authorsContainer.innerHTML = selfRef.data.supplementaryFile.authors;
        let dataTypeContainer = document.createElement('div');
        dataTypeContainer.innerHTML = selfRef.data.supplementaryFile.data_type;
        let urlContainer = document.createElement('div');
        urlContainer.innerHTML = selfRef.data.supplementaryFile.url;
        selfRef.sectionContent.components[0].defaultValue = titleContainer.textContent;
        selfRef.sectionContent.components[1].defaultValue = authorsContainer.textContent;
        selfRef.sectionContent.components[2].defaultValue = dataTypeContainer.textContent;
        selfRef.sectionContent.components[3].defaultValue = selfRef.data.supplementaryFile.brief_description;
        selfRef.fileLinkControl.setValue(urlContainer.textContent)
        let submision = {data:citationElementMap.supplementary_file_citation.getElFormIOSubmission(selfRef.data.supplementaryFile,'endEditor')}
        filterFieldsValues(selfRef.sectionContent,submision,self.serviceShare,undefined,false,supplementaryFileHTML,false)
        let filterdTableData ={
          "brief_description":submision.data.supplementaryFileBriefDescription,
        }
        titleContainer.innerHTML = submision.data.title;
        authorsContainer.innerHTML = submision.data.authors;
        dataTypeContainer.innerHTML = submision.data.data_type;
        urlContainer.innerHTML = submision.data.url;
        selfRef.sectionContent.components[3].defaultValue = filterdTableData.brief_description;
        selfRef.renderForm = true
      }else{
        selfRef.renderForm = true
      }
    } catch (e) {
      console.error(e);
    }
    }

    this.renderCodemMirrorEditors = (selfRef: any) => (supplementaryFileID: string) => {
      try {
        selfRef.supplementaryFilesTemplatesObj = self.ydocService.supplementaryFilesMap?.get('supplementaryFilesTemplates');
        let supplementaryFilesInitialTemplate = self.ydocService.supplementaryFilesMap!.get('supplementaryFilesInitialTemplate');
  
        let currSupplementalFileTemplates: any;
        if (!selfRef.supplementaryFilesTemplatesObj[supplementaryFileID]) {
          selfRef.supplementaryFilesTemplatesObj[supplementaryFileID] = { html: supplementaryFilesInitialTemplate }
          currSupplementalFileTemplates = selfRef.supplementaryFilesTemplatesObj[supplementaryFileID]
        } else {
          currSupplementalFileTemplates = selfRef.supplementaryFilesTemplatesObj[supplementaryFileID]
        }
        let prosemirrorNodesHtml = currSupplementalFileTemplates.html
  
        selfRef.codemirrorHTMLEditor = new EditorView({
          state: EditorState.create({
            doc: prosemirrorNodesHtml,
            extensions: [basicSetup, html()],
          }),
          parent: selfRef.codemirrorHtmlTemplate,
        })
        return prosemirrorNodesHtml
      } catch (e) {
        console.error(e);
      }
    }

    this.onChange = (selfRef: any) => (change: any) => {
      if(change instanceof Event){

      }else{
        selfRef.isValid = change.isValid;
        selfRef.formIoSubmission = change.data
        if(change.changed&&change.changed.instance){
          selfRef.formIoRoot = change.changed.instance.root
        }
      }
    }

    this.submitSupplementaryFile = (selfRef: any) => () => {
      if(selfRef.formIoRoot){
        selfRef.formIoRoot.submit()
      }
    }

    this.onSubmit = (selfRef: this) => async (submision?: any) => {
      try {
        let tr = selfRef.codemirrorHTMLEditor?.state.update()
        selfRef.codemirrorHTMLEditor?.dispatch(tr!);
  
        let prosemirrorNewNodeContent = selfRef.codemirrorHTMLEditor?.state.doc.sliceString(0, selfRef.codemirrorHTMLEditor?.state.doc.length);
        submision.data.supplementaryFileURL = selfRef.fileLinkControl.value
        submision.data.supplementary_file_ID = selfRef.supplementaryFileID!
  
        selfRef.supplementaryFilesTemplatesObj[selfRef.supplementaryFileID] = { html: prosemirrorNewNodeContent }
        self.ydocService.supplementaryFilesMap?.set('supplementaryFilesTemplates', selfRef.supplementaryFilesTemplatesObj)
  
        submision.data.supplementary_file_number = selfRef.data.index
  
        let newSupplementaryFile:supplementaryFile  ={
          "title":submision.data.supplementaryFileTitle,
          "authors":submision.data.supplementaryFileAuthors,
          "data_type":submision.data.supplementaryFileDataType,
          "brief_description":submision.data.supplementaryFileBriefDescription,
          "supplementary_file_number":selfRef.data.index,
          "supplementary_file_ID":submision.data.supplementary_file_ID,
          "url":submision.data.supplementaryFileURL
        }
  
        let result = { supplementaryFile: newSupplementaryFile }
        self.dialogRef.close(result)
      } catch (error) {
        console.error(error);
      }
    }
    this.fileIsUploaded = (selfRef: any) => (uploaded: any) => {
      if(uploaded.collection&&uploaded.base_url){
        selfRef.uploadedFileInCDN(selfRef)(uploaded);
      }
    }

    this.uploadedFileInCDN = (selfRef: any) => (fileData:any) => {
      selfRef.fileLinkControl.setValue(fileData.base_url);
    }
    this.defaultFileIsUploaded = this.fileIsUploaded;
    this.defaultOnChange = this.onChange(this);
    this.defaultSubmitSupplementaryFile = this.submitSupplementaryFile(this);
    this.defaultOnSubmit = this.onSubmit(this);
  }
  
  ngAfterViewInit(): void {
    if(this.modalTemplate) {
      setTimeout(() => {
        this.createComponent(this.modalTemplate);
      }, 0);
    } else {
      this.initFunc(this)();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  createComponent(template: string): void {
    const self = this;
    const data = this.data;
    const config = this.config

    const component = Component({ template })
    (class implements AfterViewInit {
      sectionContent = JSON.parse(JSON.stringify(supplementaryFileJSON));
      renderForm = false;
      data = data;
      config = config;
      section = { mode: 'editMode' }
      sectionForm = new UntypedFormGroup({})
      supplementaryFileID?: string;
      hidehtml = true;
      fileLinkControl = new UntypedFormControl('', Validators.required);
      fileType = null

      initFunc = self.initFunc;
      renderCodemMirrorEditors = self.renderCodemMirrorEditors;
      onChange = self.onChange(this);
      submitSupplementaryFile = self.submitSupplementaryFile(this);
      onSubmit = self.onSubmit(this);
      fileIsUploaded = self.fileIsUploaded(this);
      uploadedFileInCDN = self.uploadedFileInCDN;

      ngAfterViewInit(): void {
        this.initFunc(this)();
      }

      supplementaryFilesTemplatesObj: any;
      codemirrorHTMLEditor: EditorView;
      codemirrorHtmlTemplate: HTMLElement;

      isValid:boolean = false;
      formIoSubmission: any;
      formIoRoot: any;
    })

    const module = NgModule({ 
      imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatFormioModule,
        FormioModule,
        DropzoneModule,
        NgxDropzoneModule
        ],
      declarations: [
        component,
        FormControlNameDirective,
        SafePipe,
        DropzoneComponent,
        ],
      schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA] })
    (class TemplateModule { })

    const moduleFactories = this.compiler.compileModuleAndAllComponentsSync(module);
    const factory = moduleFactories.componentFactories.find(comp =>
      comp.componentType === component
    );

    if (factory) {
      this.container.createComponent(factory);
    }
  }

  ngOnDestroy(): void {
    this.container?.clear();
  }
}
