import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { AllUsersService, accessMaping, authorListData, contributorData, roleMapping } from '@app/core/services/all-users.service';
import { SendInvitationComponent } from '@app/editor/dialogs/add-contributors-dialog/send-invitation/send-invitation.component';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Component({
  selector: 'app-collaborators-auto-complete',
  templateUrl: './collaborators-auto-complete.component.html',
  styleUrls: ['./collaborators-auto-complete.component.scss']
})
export class CollaboratorsAutoCompleteComponent implements AfterViewInit, OnDestroy {

  @Input() commentmarkId?: string;
  @Output() commentmarkIdChange = new EventEmitter<string>();

  @Input() inputFormControl!: UntypedFormControl;
  @Output() inputFormControlChange = new EventEmitter<UntypedFormControl>();
  authorsList:authorListData[]
  allusers?: any[];
  searchResults: contributorData[] = []
  currCollaboratorsIneditor: any
  collabSub:Subscription
  constructor(
    public usersService: AllUsersService,
    public serviceShare: ServiceShare,
    public dialog: MatDialog,
    private changeRef: ChangeDetectorRef) {
    // should get all the users at the rendering of this component

    this.currCollaboratorsIneditor = this.serviceShare.YdocService.collaborators.get('collaborators')
    this.collabSub = this.serviceShare.YdocService.collaboratorsSubject.subscribe((collaborators)=>{
      this.currCollaboratorsIneditor = collaborators
      this.authorsList = this.serviceShare.YdocService.collaborators.get('authorsList');
    })
  }

  hide = false;

  hideResults(){
    this.hide = true;
  }

  showResults(){
    this.hide = false
  }

  ngOnDestroy(): void {
    if(this.collabSub){
      this.collabSub.unsubscribe();
    }
  }

  selectedUser(user: contributorData) {
    let inputval = this.inputFormControl.value as string

    if (this.allusers && this.emailAddRegex.test(inputval)) {
      let vals = inputval.split(this.regexToSplit);
      this.inputFormControl.setValue(vals[0] + "@" + user.email + " ")
    }
    this.hideResults();
    this.changeRef.detectChanges();
  }

  selectedUserIndex = 0;

  keyHandle(event: KeyboardEvent) {
    let key = event.key
    if (key == 'ArrowDown' && this.selectedUserIndex < this.searchResults.length - 1) {
      this.selectedUserIndex++
    } else if (key == 'ArrowUp' && this.selectedUserIndex > 0) {
      this.selectedUserIndex--
    } else if (key == 'Enter') {
      this.selectedUser(this.searchResults[this.selectedUserIndex])
    }
  }

  addDataToBackend(emailsInText: string[], newCollaborators: any[],access?:string) {
    let mappedNewCollaborators = newCollaborators.map((c) => {
      let actualUser = this.allusers.find((user) => user.email == c.email);
      if(actualUser) {
        return {
          id: c.id,
          email: c.email,
          name: c.first_name,
          role: c.role.toLocaleLowerCase(),
          affiliations: c.affiliations,
        }
      } else {
        return {
          ...c,
          role: c.roleSelect.toLocaleLowerCase(),
          affiliations: c.affiliations
        }
      }
    })
    let invitedPeople = mappedNewCollaborators;
    let mentionedPeople = this.allusers.filter((user) => {
      return (
        !mappedNewCollaborators.some((u1) => u1.email == user.email) &&
        emailsInText.some((email) => email == user.email)
      );
    })
    let articleData = {
      "id": this.serviceShare.YdocService.articleData.uuid,
      "title": this.serviceShare.YdocService.articleData.name
    }
    let message = this.inputFormControl.value;
    let commentMarkHash = this.commentmarkId

    let postBody = {
      "article": articleData,
      "message": message,
      "invited": invitedPeople,
      "mentioned": mentionedPeople,
      "hash": commentMarkHash
    }
    return this.usersService.sendCommentMentionInformation(postBody);
  }

  canFinishComment = (func: any, args: any[]) => {
    let emailsInText: string[] | null = this.inputFormControl.value.match(/[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/gm)
    if (this.currCollaboratorsIneditor && this.inputFormControl.value && this.allusers && emailsInText) {
      let newCollaborators: any[] = [];
      emailsInText.forEach((email) => {
        if (!this.currCollaboratorsIneditor.collaborators.find((collab) => {
          return collab.email == email
        })) {
          newCollaborators.push(email);
        }
      })
      if (newCollaborators.length > 0) {
        let mappedNewCollaborators = newCollaborators.map((email) => {
          let actualUser = this.allusers.find((user) => user.email == email)
          if (actualUser) {
            return actualUser
          } else {
            return { email, name: '' }
          }
        })
        // should add contributers to editor do finish comment add
        const dialogRef = this.dialog.open(SendInvitationComponent, {
          maxWidth: '80%',
          data: { contributor: mappedNewCollaborators, fromComment: true },
        });

        dialogRef.afterClosed().subscribe((result:{
          'usersChipList': any,
          'notifyingPeople': any,
          'accessSelect': string,
          'roleSelect': string,
          'affiliations':{
            affiliation:string,
            city:string,
            country:string
          }[],
          'message': string
        }) => {
          if (result && result.usersChipList.length > 0 && this.currCollaboratorsIneditor) {
            this.serviceShare.ProsemirrorEditorsService.spinSpinner();
            let collaboratorsCopy = [...this.currCollaboratorsIneditor.collaborators];
            result.usersChipList.forEach((newColaborator) => {
              if(newColaborator.id == null) {
                const id = newColaborator.email;
                collaboratorsCopy.push({ ...newColaborator,role:result.roleSelect,affiliations:result.affiliations, id });
              } else {
                collaboratorsCopy.push({ 
                  name: newColaborator.name, 
                  first_name: newColaborator.first_name,
                  last_name: newColaborator.last_name,
                  email: newColaborator.email,
                  id: newColaborator.id,
                  role: result.roleSelect,
                  access: roleMapping[result.roleSelect],
                  affiliations: result.affiliations
                });
              }
            })
            if(!this.authorsList){
              this.authorsList = this.serviceShare.YdocService.collaborators.get('authorsList');
            }
            let authorsListCopy:authorListData[] = [...this.authorsList];
            if(result.roleSelect == "author" || result.roleSelect == "author_commenter"){
              authorsListCopy.push(...result.usersChipList.map((user: any)=>{
                if(!user.id || user.id == null) {
                  const authorId = user.email;
                  return { authorId, authorEmail:user.email };
                } else {
                  return { authorId:user.id, authorEmail:user.email };
                }
              }));
            }











            this.addDataToBackend(emailsInText, newCollaborators.map((email: string) => collaboratorsCopy.find(c => c.email == email)),result.roleSelect).subscribe((data)=>{
              this.serviceShare.YdocService.collaborators.set('collaborators', { collaborators: collaboratorsCopy });
              this.serviceShare.YdocService.collaborators.set('authorsList', authorsListCopy)
              this.serviceShare.ProsemirrorEditorsService.stopSpinner()
              func(...args)
            },
            (err) => {
              console.error(err)
              this.serviceShare.ProsemirrorEditorsService.stopSpinner()
            });
          }
        });

      } else {
        this.serviceShare.ProsemirrorEditorsService.spinSpinner()
        this.addDataToBackend(emailsInText, newCollaborators).subscribe((data)=>{
              this.serviceShare.ProsemirrorEditorsService.stopSpinner()
          func(...args)
        },
        (err) => {
          console.error(err)
          this.serviceShare.ProsemirrorEditorsService.stopSpinner()
        });
      }
    } else {
      func(...args)
    }

  }

  loading = false;

  regexToSplit = /@\S*$/gm
  emailAddRegex = /( |^)@\S*$/gm
  emailAddRegexMathStart = /( |^)@+/gm
  lastSearchVal = ''
  ngAfterViewInit(): void {
    this.inputFormControl.valueChanges.pipe(
      debounceTime(300),
      switchMap((value: string) => {
        this.loading = true;
        this.searchResults = [];
        if (this.emailAddRegex.test(value)) {
          const searchVal = value.match(this.emailAddRegex)[0].replace(this.emailAddRegexMathStart, '');
          this.showResults();
          this.lastSearchVal = searchVal;
          return this.usersService.getAllUsers({ page:1, pageSize:10, 'filter[search]': searchVal });
        } else {
          this.searchResults = [];
          this.loading = false;
          return EMPTY;
        }
    })).subscribe((data: any) => {
      if (data) {
        this.allusers = data;
        this.searchResults = data;
        this.selectedUserIndex = 0;
      }
      this.loading = false;
      this.changeRef.detectChanges();
    })
  }

}
