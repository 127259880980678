import { RendererFactory2 } from '@angular/core';
import { EditorView } from 'prosemirror-view';
import { Node } from 'prosemirror-model';
import { ServiceShare } from './service-share.service';

export class CustomView {
    dom: any;
    constructor(private node: Node, private view: EditorView, private getPos: any, private rendererFactory: RendererFactory2, private serviceShare: ServiceShare) {
      const renderer = this.rendererFactory.createRenderer(null, null);
      this.dom = renderer.createElement('custom-pm-element');
      this.dom.id = node.attrs.id;
    }

    destroy() { }
}
