import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Injectable, Input, NgModule, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {UntypedFormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/shared/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[] | undefined;
  item: string | undefined;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string | undefined;
  level: number | undefined;
  expandable: boolean | undefined;
}

/**
 * The Json object for to-do list data.
 */

/*const TREE_DATA = {
  Groceries: {
    'Almond Meal flour': null,
    'Organic eggs': null,
    'Protein Powder': null,
    Fruits: {
      Apple: null,
      Berries: ['Blueberry', 'Raspberry'],
      Orange: null
    }
  },
  Reminders: [
    'Cook dinner',
    'Read the Material Design spec',
    'Upgrade Application to Angular'
  ]
};*/

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {

  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  dataFromSource = new BehaviorSubject([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: AppConfig) {
    this.initialize();
  }

  fetchChildNodes(node: any): Observable<any> {
    return this.http.get(`${this.config.taxonSearch}/taxons/classifications/${node.item.pos}`);
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    // this.http.get(`https://something/journaltree`).subscribe(res => {
    //   this.buildNestedJson(res);
    // })
    this.http.get(`${this.config.taxonSearch}/taxons/classifications`).subscribe(res => {
      //@ts-ignore
      this.dataFromSource.next([...res, ...this.dataFromSource._value]);
      this.buildNestedJson(res);
    })
    // const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    // this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */

  /*buildFileTree(obj: {[key: string]: any}, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }*/
  buildNestedJson(res: any, moreData?: boolean) {
    if(!res.length) return;
    
    let TREE_DATA = {};
    res.forEach((node: any) => {
      let strL = node.pos.length;
      let positions = []
      for(let i = 0 ; i < strL-1;i+=2){
        let char = node.pos[i];
        let char2 = node.pos[i+1];
        positions.push(char+char2)
      }
      positions.reduce((next: any, current: any, index: any) => {
        if (!next[current]) {
          if (positions.length - 1 === index) {
            next[current] = node;
          } else {
            next[current] = {};
          }
        }
        if (positions.length - 1 === index) {
          next[current] = {...next[current], ...node};
        }
        return next[current];
      }, TREE_DATA)
    });

    const recFunc = (data: any) => {
      if(Object.keys(data).length > 1) {
        return data;
      } else if (data[Object.keys(data)[0]].name) {
        return data;
      } else {
        return recFunc(data[Object.keys(data)[0]]);
      }
    }
    
    TREE_DATA = recFunc(TREE_DATA);
    
    const data = this.buildFileTree(TREE_DATA, 0);
    if(moreData) {
      return data;
    } else {
      this.dataChange.next(data);
    }
  }

  buildFileTree(obj: { [key: string]: any }, level: number): any[] {
    // @ts-ignore
    return Object.keys(obj).filter(s => {
      return !['id', 'name', 'journal_ids', 'rootnode', 'pos', 'nomenclaturalCode', 'parents'].includes(s)
    }).sort(
      // @ts-ignore
      (a: any, b: any) => {
        if (obj[a].name < obj[b].name) {
          return -1;
        }
        if (obj[a].name > obj[b].name) {
          return 1;
        }
        return 0;
      }).reduce<any[]>((accumulator, key) => {
      const value = obj[key];
      const node: any = {};
      // const node = new TodoItemNode();
      const {id, name, journal_ids, rootnode, pos, nomenclaturalCode, parents} = value;
      node.item = {id, name, journal_ids, rootnode, pos, nomenclaturalCode, parents};
      // node.item = key;

      if (value != null) {
        if (typeof value === 'object' && Object.keys(obj).filter(s => {
          return !['id', 'name', 'journal_ids', 'rootnode', 'pos', 'nomenclaturalCode', 'parents'].includes(s)
        }).length) {
          node.children = value.is_leaf ? [] : this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, node: any) {
    if (parent.children) {
      parent.children.push(node);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-tree-checklist',
  templateUrl: './tree-checklist.component.html',
  styleUrls: ['./tree-checklist.component.scss'],
  providers: [ChecklistDatabase,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TreeChecklistComponent
    }]
})
export class TreeChecklistComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() control: UntypedFormControl;

  myControl = new UntypedFormControl();
  options = [] //: any = journalTree.map((el: any) => el.name);
  filteredOptions: Observable<string[]> | undefined;

  @ViewChild('input', { read: ElementRef }) input: ElementRef;

  updateMySelection(event: any) {
    let result: TodoItemFlatNode;
    for (const [key, value] of this.flatNodeMap) {
      // @ts-ignore
      if (value.item.name == event.option.value.name) {
        result = key;
      }
    }

    if(result) {
      this.writeValue(event.option.value.name);
    } else {
      const nodesThatShouldBeAdded = [];
      const iterateParents = (parent: any) => {
        //@ts-ignore
        const node = Array.from(this.flatNodeMap.entries()).find(([key, value]) => value.item.name == parent.name);
        if(node && node[0]) {
          nodesThatShouldBeAdded.push(event.option.value);
          let parentToAdd = node[0];
          nodesThatShouldBeAdded.forEach((n, index, arr) => {
            const children: TodoItemNode[] = this._database.buildNestedJson([{...n, is_leaf: false}], true);        
            this.addNewItem(parentToAdd, children);
            const item = this.nestedNodeMap.get(children[0]);
            if(item) {
              parentToAdd = item;
            }
          })
        } else {
          nodesThatShouldBeAdded.unshift(parent);
          iterateParents(parent.parents[0]);
        }
      }
      
      iterateParents(event.option.value.parents[0]);
      this.writeValue(event.option.value.name);
    }
  }

  ngOnInit() {

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option: string) => option.toLowerCase().includes(filterValue)).filter((i: any, index: any) => {
      return index < 11;
    });
  }
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(false /* multiple */);

  onChange = (selection: any) => {
  };

  onTouched = () => {
  };

  touched = false;

  disabled = false;

  selectedNode: any = null;
  subscription = new Subscription();
  constructor(private _database: ChecklistDatabase, private changeRef: ChangeDetectorRef, private httpClient: HttpClient) {
    // @ts-ignore
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    // @ts-ignore
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.subscription.add(_database.dataChange.subscribe(data => {
      this.options = data.map((el: any) => el.item.name);
      this.dataSource.data = data;
    }));
  }

  ngAfterViewInit(): void {
    this.subscription.add(this.myControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      if(value) {
        this.loading = true;
        this.searchClassification(value);
      }
    }));
  }

  searchClassification(value: string) {
    this.subscription.add(this.httpClient.get(`${this._database.config.taxonSearch}/taxons/classifications/search/${value}`).subscribe({
      next: (res: any[]) => {
        this.searchData = res;
        this.loading = false;
      },
      error: (err) => {
        console.error(err);
        this.loading = false;
      }
    }));
  }

  loading = false;
  searchData = [];

  displayFn(option: any): string {
    if (option) {
      return option.name;
    }
    return '';
  }

  writeValue(value: any) {
    this.selectedNode = value;
    let result: any = null;
    for (const [key, value] of this.flatNodeMap) {
      // @ts-ignore
      if (value.item.name == this.selectedNode) {
        result = key;
      }
      // value?.children?.forEach((child: any) => {
      //     // @ts-ignore
      //     if (child.item.name == this.selectedNode) {
      //       result = key;
      //     }
      // })
    }
    if (result) {
      this.todoItemSelectionToggle(result);
      result.item.parents?.forEach((id: any) => {
        for (const [k, val] of this.flatNodeMap) {
          // @ts-ignore
          if (val.item.id == id) {
            this.treeControl.expand(k);
          }
        }
      })
    }
    this.treeControl.expand(result);

    // [0].value.item
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] | undefined => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.expandable
  };

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    // @ts-ignore
    this.selectedNode = node.item.name;
    this.selectedItem = node;
    this.onChange(this.selectedNode);
    // this.myControl.setValue(this.selectedNode);
    if(!this.checklistSelection.isSelected(node)) {
      this.control.setValue("");
      this.myControl.setValue("");
    }

    // const descendants = this.treeControl.getDescendants(node);
    // this.checklistSelection.isSelected(node)
    //   ? this.checklistSelection.select(...descendants)
    //   : this.checklistSelection.deselect(...descendants);
    //
    // // Force update for the parent
    // descendants.forEach(child => this.checklistSelection.isSelected(child));
    // this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: any): void {
    this.selectedNode = node.item.name;
    this.checklistSelection.toggle(node);
    this.onChange(this.selectedNode);
    // this.myControl.setValue(this.selectedNode);
    if(!this.checklistSelection.isSelected(node)) {
      this.control.setValue("");
      this.myControl.setValue("");
    }

    // this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    // @ts-ignore
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      // @ts-ignore
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode, children: TodoItemNode[]) {
    const parentNode = this.flatNodeMap.get(node);
    children.forEach(ch => this._database.insertItem(parentNode, ch));
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }

  selectedItem: TodoItemFlatNode;

  remove(): void {
    this.checklistSelection.deselect(this.selectedItem);
    this.control.setValue("");
    this.myControl.setValue("");
    setTimeout(() => {
      (this.input.nativeElement as HTMLInputElement).focus();
    }, 100);
  }
  isLoadingChildren = false;
  onNodeExpanded(node: TodoItemFlatNode) {
    if (this.treeControl.isExpanded(node) && this.flatNodeMap.get(node).children.length == 1) {
      this.isLoadingChildren = true;
      //@ts-ignore
      this.lastSelectedId = node.item.id;
      this._database.fetchChildNodes(node).subscribe((res: any[]) => {
        this.isLoadingChildren = false;
        this.lastSelectedId = undefined;
        const children: TodoItemNode[] = this._database.buildNestedJson(res, true);
        if(children) {
          this.addNewItem(node, children);
          this.changeRef.detectChanges();
        }      
      }, error => {
        console.error('Failed to fetch children:', error);
      });
    }
  }
  lastSelectedId: string;
  isClicked(node: TodoItemFlatNode) {
    //@ts-ignore
    return node.item.id == this.lastSelectedId;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

@NgModule({
  declarations: [
    TreeChecklistComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ],
  exports: [
    TreeChecklistComponent
  ]
})

export class ChacklistModule { }
