import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Compiler, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule, NO_ERRORS_SCHEMA, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { FormControlNameDirective } from '@app/editor/directives/form-control-name.directive';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { MatFormioModule } from '@app/formio-angular-material/angular-material-formio.module';
import { SafePipe } from '@app/formio-angular-material/components/MaterialComponent';
import { MaterialModule } from '@app/shared/material.module';
import { FormioModule } from '@formio/angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule, DropzoneComponent } from 'ngx-dropzone-wrapper';
import { formioAuthorsDataGrid, formIOTextFieldTemplate, reference } from '../data/data';
import { CiToTypes } from '../lib-service/editors-refs-manager.service';
import { SaveComponent } from './save/save.component';

@Component({
  selector: 'app-reference-edit',
  templateUrl: './reference-edit.component.html',
  styleUrls: ['./reference-edit.component.scss']
})
export class ReferenceEditComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;

  referenceForms: UntypedFormGroup = new UntypedFormGroup({})
  formIOSchema: any = undefined;
  referenceFormControl = new UntypedFormControl(null, [Validators.required]);
  stylesFormControl = new UntypedFormControl(null, [Validators.required]);
  referenceTypesFromBackend: any[] = []
  dataSave:any
  referenceStyles:any
  CiToTypes = CiToTypes
  citoFormControl = new UntypedFormControl(null);

  modalTemplate: string;

  initFunc: (selfRef: any) => () => void;
  onSubmit: (selfRef: any) => (submission: any) => void;
  onChange: (selfRef: any) => (change: any) => void;
  submitRef: (selfRef: any) => () => void;
  generateFormIOJSON: (selfRef: any) => (type: any) => void;

  defaultSubmitRef: () => void;
  defaultOnSubmit: (submission: any) => void;
  defaultOnChange: (change: any) => void;
  defaultGenerateFormIOJSON: (type: any) => void;

  isValid:boolean = true;
  formIoSubmission:any
  formIoRoot:any
  isModified:boolean = false;
  isEditMode = false;

  constructor(
    public dialogRef: MatDialogRef<ReferenceEditComponent>,
    public dialog: MatDialog,
    private compiler: Compiler,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService:ServiceShare,
    private cahngeDetectorRef: ChangeDetectorRef
  ) {
    this.modalTemplate = this.sharedService.YdocService.referenceCitationsMap.get("referencesModalTemplate");
    const self = this;

    this.initFunc = (selfShare: any) => () => {
      selfShare.isEditMode = selfShare.data.editMode;
      selfShare.referenceTypesFromBackend = selfShare.data.referenceTypesFromBackend;
      selfShare.referenceStyles = selfShare.data.referenceStyles
      if (!selfShare.data.oldData) {
        selfShare.referenceFormControl.setValue(selfShare.referenceTypesFromBackend[0]);
        selfShare.stylesFormControl.setValue(selfShare.referenceStyles[0])
      } else {
        let oldBuildData = selfShare.data.oldData;
        if(oldBuildData.refCiTO&&selfShare.CiToTypes.find((cito) => {
          return (cito.label == oldBuildData.refCiTO.label)
        })){
          let indexCito = selfShare.CiToTypes.findIndex((cito) => {
            return (cito.label == oldBuildData.refCiTO.label)
          });
          selfShare.citoFormControl.setValue(selfShare.CiToTypes[indexCito])
        }else{
          selfShare.citoFormControl.setValue(null)
        }
        if (selfShare.referenceTypesFromBackend.find((ref) => {
          return (ref.name == oldBuildData.refType.name||ref.type == oldBuildData.refType.type)
        })) {
          let index = selfShare.referenceTypesFromBackend.findIndex((ref) => {
            return (ref.name == oldBuildData.refType.name||ref.type == oldBuildData.refType.type)
          });
  
          selfShare.referenceFormControl.setValue(selfShare.referenceTypesFromBackend[index]);
        }else{
          selfShare.referenceFormControl.setValue(selfShare.referenceTypesFromBackend[0]);
        }
  
        if (selfShare.referenceStyles.find((style:any) => {
          return style.name == oldBuildData.refStyle.name
        })) {
          let index = selfShare.referenceStyles.findIndex((style:any) => {
            return style.name == oldBuildData.refStyle.name
          });
          selfShare.stylesFormControl.setValue(selfShare.referenceStyles[index]);
        }else{
          selfShare.stylesFormControl.setValue(selfShare.referenceStyles[0])
        }
  
      }
  
      selfShare.generateFormIOJSON(selfShare.referenceFormControl.value);
    }

    this.onSubmit = (selfRef: any) => (submission: any) => {
      self.dialogRef.close({
        submissionData: submission,
        referenceScheme: selfRef.referenceFormControl.value,
        referenceStyle: selfRef.stylesFormControl.value,
        refCiTO: selfRef.citoFormControl.value,
      })
    }

    this.onChange = (selfRef: any) => (change: any) => {
      if(change instanceof Event){
  
      }else{
        selfRef.isValid = change.isValid
        selfRef.formIoSubmission = change.data
        selfRef.isModified = change.isModified
  
        if(selfRef.isEditMode && JSON.stringify(selfRef.data.oldData.refData.formioData) == JSON.stringify(change.data)) {//oldData, refData, formioData
          selfRef.isValid = false;
        }

        if(change.changed&&change.changed.instance){
          selfRef.formIoRoot = change.changed.instance.root
        }
      }
    }

    this.submitRef = (selfRef: any) => () => {
      if(selfRef.formIoRoot){
        const editGridComponent = selfRef.formIoRoot.components[0];
        editGridComponent.editRows.forEach((row: any, index: number) => {
          if(row.state === 'editing' || row.state === 'new') {
            editGridComponent.cancelRow(index);
          }
        });
        selfRef.formIoRoot.submit();
      }
    }

    this.generateFormIOJSON = (selfRef: any) => (type: any) => {
      selfRef.formIOSchema = undefined;
      // selfRef.cahngeDetectorRef.detectChanges()
  
      let newFormIOJSON = JSON.parse(JSON.stringify(type.formIOScheme));
      let oldFormIOData = selfRef.dataSave?selfRef.dataSave:selfRef.data.oldData?selfRef.data.oldData.refData.formioData:undefined;
      self.sharedService.FormBuilderService.setAutoFocusInSchema(newFormIOJSON);
      newFormIOJSON.components.forEach((component:any)=>{
        let val = oldFormIOData?oldFormIOData[component.key]:undefined;
        if(val){
          component.defaultValue = val
        }
      })
      if ((newFormIOJSON.components as Array<any>).find((val) => {
        return (val.key == 'submit' && val.type == 'button')
      })) {
        newFormIOJSON.components = newFormIOJSON.components.filter((val) => {
          return (val.key != 'submit' || val.type != 'button')
        })
      }
      setTimeout(() => {
        selfRef.formIOSchema = newFormIOJSON;
        // selfRef.cahngeDetectorRef.detectChanges();
      }, 100)
      return
    }

    this.defaultSubmitRef = this.submitRef(self);
    this.defaultOnSubmit = this.onSubmit(self);
    this.defaultOnChange = this.onChange(self);
    this.defaultGenerateFormIOJSON = this.generateFormIOJSON(self);
  }

  ngAfterViewInit(): void {
    if(this.modalTemplate) {
      setTimeout(() => {
        this.createComponent(this.modalTemplate);
      }, 0);
    } else {
      this.initFunc(this)();
    }
  }
  
  ngAfterViewChecked(): void {
    this.cahngeDetectorRef.detectChanges();
  }

  createComponent(template: string): void {
    const self = this;
    const data = this.data;

    const component = Component({ template })
    (class implements AfterViewInit {
      referenceForms: UntypedFormGroup = new UntypedFormGroup({})
      formIOSchema: any = undefined;
      referenceFormControl = new UntypedFormControl(null, [Validators.required]);
      stylesFormControl = new UntypedFormControl(null, [Validators.required]);
      referenceTypesFromBackend: any[] = []
      dataSave:any
      data = data;
      referenceStyles:any
      CiToTypes = CiToTypes
      citoFormControl = new UntypedFormControl(null);
      isValid:boolean = true;
      formIoSubmission:any
      formIoRoot:any
      isModified:boolean = false;
      isEditMode = false;

      initFunc = self.initFunc;
      generateFormIOJSON = self.generateFormIOJSON(this);
      submitRef = self.submitRef(this);
      onChange = self.onChange(this);
      onSubmit = self.onSubmit(this);

      ngAfterViewInit(): void {
        this.initFunc(this)();
      }
    })

    const module = NgModule({ 
      imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatFormioModule,
        FormioModule
        ],
      declarations: [
        component,
        FormControlNameDirective,
        SafePipe,
        ],
      schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA] })
    (class TemplateModule { })

    const moduleFactories = this.compiler.compileModuleAndAllComponentsSync(module);
    const factory = moduleFactories.componentFactories.find(comp =>
      comp.componentType === component
    );

    if (factory) {
      this.container.createComponent(factory);
    }
  }

  ngOnDestroy(): void {
    this.container?.clear();
  }
}
