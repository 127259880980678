import { AfterViewInit, Component } from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements AfterViewInit {

  constructor(private serviceShare: ServiceShare) { }

  ngAfterViewInit(): void {
    this.serviceShare.ProsemirrorEditorsService.stopSpinner();
  }
}
