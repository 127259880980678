function matchAction(/* request */key1: string,/* policy */ key2: string) {
  let test;
  try {
    test = new RegExp(key2).test(key1);
    // console.log(test);
    
    return test
  } catch (e) {
    // debugger
    // console.log(key2, key1);
    // console.log(test);
    
    return true;
    // console.error(e);
    
  }
  // return new RegExp(key2).test(key1);
}
function log(...args){
  // console.log(args);
  
  return true;
}

export { matchAction,log };
