import { newModelFromString } from "casbin";

const modelDefinition = `
  [request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = priority(p.eft) || deny

[matchers]
m = g(r.sub, p.sub) && ( keyMatch(r.obj,p.obj) || aclFunction(r.obj, p.obj, p.act, p.eft, r.act, r.sub, p.sub) ) && matchAction(r.act, p.act) && log(r.obj,p.obj,r.act,p.act,p.eft)
`;

export function getModel() {
  return newModelFromString(modelDefinition);
}
