import { ApplicationRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

import { YdocService } from '../../services/ydoc.service';
import { articleSection } from '../../utils/interfaces/articleSection';
import { EditSectionService } from './edit-section.service';
import { ProsemirrorEditorsService } from '../../services/prosemirror-editors.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-edit-section-dialog',
  templateUrl: './edit-section-dialog.component.html',
  styleUrls: ['./edit-section-dialog.component.scss']
})

export class EditSectionDialogComponent implements OnInit, OnDestroy {
  data: articleSection;
  showSection = false;
  data1: articleSection;
  sectionForm: UntypedFormGroup;
  prityJson:string;
  sectionContent: any;

  EditSubmitsubscription?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<EditSectionDialogComponent>,
    public prosemirrorService: ProsemirrorEditorsService,
    public ydocService: YdocService,
    public AppRef : ApplicationRef,
    public editSectionService: EditSectionService,
    @Inject(MAT_DIALOG_DATA) public sectionData: any) {
      this.data1 = sectionData.node;
      this.sectionForm = sectionData.form;
      this.sectionContent = sectionData.sectionContent;
      this.prityJson = JSON.stringify(sectionData.formIOJson,null,"\t")

  }

  ngOnInit(): void {
    try {
      this.data = JSON.parse(JSON.stringify(this.data1));
      this.copySection(this.data!);
      this.EditSubmitsubscription = this.editSectionService.editChangeSubject.subscribe((submit:any) => {
        this.dialogRef.close({...submit,section:this.data})
        this.EditSubmitsubscription.unsubscribe()
        delete this.EditSubmitsubscription
        return
      })
    } catch (e) {
      console.error(e);
    }
  }

  copySection(data: articleSection) {
    data.mode = 'editMode'
  }

  ngOnDestroy(){
    this.EditSubmitsubscription?.unsubscribe();
  }
}
