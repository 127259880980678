import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter
} from '@angular/core';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, pairwise, take} from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import {HttpClient} from "@angular/common/http";
import {MatSelect} from "@angular/material/select";
import { initial } from 'lodash';

@Component({
  selector: 'app-ngx-mat-select-search-wrapper',
  templateUrl: './ngx-mat-select-search-wrapper.component.html',
  styleUrls: ['./ngx-mat-select-search-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxMatSelectSearchWrapperComponent),
      multi: true
    }
  ]
})
export class NgxMatSelectSearchWrapperComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() showToggleAllCheckbox = false;
  @Input() toggleAllCheckboxTooltipMessage: string = 'Toggle all';
  @Input() toggleAllCheckboxTooltipPosition: any = 'above';
  @Input() customClearIcon: string | undefined;
  @Input() sectionID: string;
  @Input() initialContent: any;
  @Input() title: string;
  private _select: MatSelect | undefined;

  @Output() visibilityIconClicked = new EventEmitter<any>();

  isIndeterminate = false;
  isChecked = false;

  @ViewChild(MatSelect, { static: false })
  set select(value: MatSelect | undefined) {
    this._select = value;
    if (this._select) {
      this._select.placeholder = this.placeholder;
      this._select.panelClass = 'ngx-mat-select-search-panel';
    }
  }

  get select(): MatSelect | undefined {
    return this._select;
  }
  private _optionsSource?: Array<any> | { search?: any; method: string; url: string };
  @Input()
  set optionsSource(value: Array<any> | { search?: any; method: string; url: string } | undefined) {
    this._optionsSource = value;
    this.handleOptions();
  }

  get optionsSource(): Array<any> | {
    search?: any;
    method: string;
    url: string } | undefined {
    return this._optionsSource;
  }

  @Input() multiple: boolean = false;

  private options: any[] = [];
  public filtered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _formControl: UntypedFormControl = new UntypedFormControl();

  get formControl(): UntypedFormControl {
    return this._formControl;
  }

  private _valueChangesUnsubscribe = new Subject<void>();

  @Input()
  set formControl(value: UntypedFormControl) {
    if (value) {
      // Unsubscribe from the previous formControl value changes (if any)
      this._valueChangesUnsubscribe.next();

      this._formControl = value;
      // Remove the redundant value changes subscription here
    }
  }

  filterCtrl: UntypedFormControl = new UntypedFormControl();
  private _onDestroy = new Subject<void>();

  constructor(private http: HttpClient) {
  }

  @Input() placeholder: string = '';
  @Input() panelClass: string = '';


  ngOnInit() {
    // Load the initial options list
    this.selectedOptions = this.formControl.value || [];
    this.filtered.next(this.options.slice());

    // Listen for search field value changes
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        if (!this.options) {
          return;
        }
    
        let search = this.filterCtrl.value;
        if (!search) {
          this.filtered.next(this.options.slice());
          return;
        } else {
          search = search.toLowerCase();
        }
    
        this.filtered.next(
          this.options.filter(option => option.title.toLowerCase().indexOf(search) > -1)
        );
        this.setToggleAllCheckboxState();
      });

    if (this.formControl) {
      this.formControl.valueChanges
        .pipe(
          takeUntil(this._onDestroy),
          pairwise(),
          distinctUntilChanged(([prev], [curr]) => {
            return JSON.stringify(prev) === JSON.stringify(curr);
          })
        )
        .subscribe(([_, value]) => {
          this.onChange(value);
          this.onTouched();
          this.setToggleAllCheckboxState();
        });
    }
  }

  onRemoved(option: any): void {
    const index = this.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.formControl.setValue([...this.selectedOptions]);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private loadOptions() {
    if (Array.isArray(this.optionsSource)) {
      this.options = this.optionsSource;
      this.filtered.next(this.options.slice());
    }
  }

  private handleOptions(): void {
    if (!this.optionsSource) {
      return;
    }

    if (Array.isArray(this.optionsSource)) {
      this.options = this.optionsSource;
      this.filtered.next(this.options.slice());
    }
  }

  private filterOptions() {
    if (!this.options) {
      return;
    }

    let search = this.filterCtrl.value;
    if (!search) {
      this.filtered.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filtered.next(
      this.options.filter(option => option.title.toLowerCase().indexOf(search) > -1)
    );
    this.setToggleAllCheckboxState();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filtered
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (selectAllValue) {
          this.formControl.patchValue([...this.options]);
        } else {
          this.formControl.patchValue([]);
        }
      });
  }

  protected setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.formControl && this.formControl.value && this.formControl.value.length) {
      this.filtered.subscribe(res => {
        res.forEach(el => {
          if (this.formControl.value.indexOf(el.title) > -1) {
            filteredLength++;
          }
        });
        this.isIndeterminate = filteredLength > 0 && filteredLength < res.length;
        this.isChecked = filteredLength > 0 && filteredLength === res.length;
      })
    }
  }

  openedChange(event: any) {
    if(event) {
      this.setToggleAllCheckboxState();
    }
  }

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  writeValue(value: any): void {
    // if (value !== undefined) {
    //   this.selectedOptions = value;
    //   this.formControl.setValue(value, { emitEvent: false });
    // }
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  emitValue(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const titles = this.formControl.value.map((val: {
      title: string;
      section: Element;
      initial?: boolean;
  }) => !val.initial ? val.title : "Original Title");
    this.visibilityIconClicked.emit({ choosed: this.formControl.value, sectionID: this.sectionID, titles });
  }

  selectedOptions: any[] = [];

  changeHandler(option: any) {
    const isSelected = this.selectedOptions.includes(option);
  
    if (isSelected) {
      this.selectedOptions = this.selectedOptions.filter(item => item !== option);
    } else {
      this.selectedOptions.push(option);
    }
    this.formControl.setValue(this.selectedOptions);
  }
  
}
