import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { material } from '@app/core/services/custom_sections/material';
import { TreeService } from '@app/editor/meta-data-tree/tree-service/tree.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { getFilteredSectionChooseData, getSectionBasicStructure } from '@app/editor/utils/articleBasicStructure';
import { articleSection } from '@app/editor/utils/interfaces/articleSection';
import { uuidv4 } from 'lib0/random';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';

export interface LinksData {
  externalLinks: ExternalLinks[],
  link: string,
  label: string,
  select: string,
}

interface ExternalLinks {
  link: string,
  label: string,
  select: string,
}

interface TaxonTreatment {
  taxa: Record<string, any>,
  materials: Record<string, any>[],
  externalLinks: ExternalLinks[],
}

@Component({
  selector: 'app-taxon-treatments-section',
  templateUrl: './taxon-treatments-section.component.html',
  styleUrls: ['./taxon-treatments-section.component.scss']
})
export class TaxonTreatmentsSectionComponent implements OnInit {

  @Input() onSubmit!: (data: any) => Promise<any>;
  @Output() onSubmitChange = new EventEmitter<(data: any) => Promise<any>>();
  @Input() fGroup!: FormGroup;

  @Input() section!: articleSection;
  @Output() sectionChange = new EventEmitter<articleSection>();

  @Input() triggerCustomSecSubmit: Subject<any>;
  @Output() triggerCustomSecSubmitChange = new EventEmitter<Subject<any>>();

  @Output() async triggerSubmit() {
    await this.onSubmit({});
  }

  taxonTreatmentsData: Record<string, TaxonTreatment>;
  totalTaxons: number;
  processedTaxons = 0;
  progress = 0;
  isProcessing = false;
  isRendering = false;

  constructor(
    private treeService: TreeService,
    private serviceShare: ServiceShare,
    public dialogRef: MatDialogRef<TaxonTreatmentsSectionComponent>,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.triggerCustomSecSubmit.subscribe(()=>{
      this.triggerSubmit();
    });
  }

  /**
  * Converts an ArrayBuffer into a string representation.
  *
  * @param arrayBuffer - The buffer containing binary data.
  * @returns - A string representation of the provided ArrayBuffer.
  */
  parseExcel(arrayBuffer: any) {
    const data = new Uint8Array(arrayBuffer);
    const arr = [];
    for (let i = 0; i !== data.length; ++i) {
      arr[i] = String.fromCharCode(data[i]);
    }
    return arr.join('');
  }

    /**
  * Converts an Excel serial date to a JavaScript date in the format 'dd/mm/yyyy'.
  *
  * @param serial - The Excel serial date number.
  */
  excelSerialDateToJSDate(serial: number) {
    const jsDate = new Date(Date.UTC(1899, 11, 30));
    const days = serial;

    jsDate.setDate(jsDate.getDate() + days);

    const day = jsDate.getDate();
    const month = jsDate.getMonth() + 1;
    const year = jsDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

    /**
  * Validates a given taxon treatment entry to ensure it has values.
  * A taxon treatment entry is considered valid if:
  * - The `taxa` object has properties (is not empty)
  * - The `materials` array has elements (is not empty)
  * - The `externalLinks` array has elements (is not empty)
  *
  * @param entry - The taxon treatment entry to validate.
  */
  isValidTaxonTreatment(entry: TaxonTreatment): boolean {
    const hasValidTaxa = Object.keys(entry.taxa).length > 0;
    const hasValidMaterials = entry.materials.length > 0;
    const hasValidExternalLinks = entry.externalLinks.length > 0;

    return hasValidTaxa || hasValidMaterials || hasValidExternalLinks;
  }

    /**
  * Processes each sheet in the workbook and transforms it into a JSON format. 
  * Each taxon, material, and external link is identified by its localID.
  *
  * @param workbook - The Excel workbook to convert.
  * @returns { object } - A JSON representation of the workbook's content. The returned object 
  */
  convertWorkbookToJson(workbook: XLSX.WorkBook): any {
    const taxonTreatmentsObj: Record<string, TaxonTreatment> = {};

    workbook.SheetNames.forEach((sheetName) => {
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
    
      sheetData.forEach((data: Record<string, any>) => {
        let localID = data['Taxon_Local_ID'];
        delete data['Taxon_Local_ID'];

        let newData = data;

        if(sheetName === 'Taxa') {
          newData = this.convertKeysToLowerCase(data);
        }
      
        if (!taxonTreatmentsObj[localID]) {
          taxonTreatmentsObj[localID] = {
            taxa: {},
            materials: [],
            externalLinks: []
          };
        }

        this.populateTaxonTreatments(taxonTreatmentsObj, localID, newData, sheetName);
      });
    });

    // Check if all entries are valid
    const allValid = Object.values(taxonTreatmentsObj).every(this.isValidTaxonTreatment);

    if (!allValid) {
      this.invalidFile();
      return;
    }

    return taxonTreatmentsObj;
  }

  convertKeysToLowerCase(obj: Record<string, any>): Record<string, any> {
    const lowerCaseObj: Record<string, any> = {};

    Object.keys(obj).forEach(key => {
      lowerCaseObj[key.toLocaleLowerCase()] = obj[key];
    });

    return lowerCaseObj;
  } 

    /**
 * Populates the taxonTreatmentsObj based on the provided sheet name.
 *
 * @param taxonTreatmentsObj - The main object where processed taxon treatments information will be stored.
 * @param localID - The local identifier for the taxon.
 * @param newData - The raw data from the Excel sheet.
 * @param sheetName - The name of the sheet which determines the data type.
 */
  populateTaxonTreatments(taxonTreatmentsObj: Record<string, TaxonTreatment>, localID: string, newData: Record<string, any>, sheetName: string): void {
    switch (sheetName) {
      case 'Taxa':
        this.handleTaxaData(taxonTreatmentsObj, localID, newData);
        break;
      case 'Materials':
        this.handleMaterialData(taxonTreatmentsObj, localID, newData);
        break;
      case 'ExternalLinks':
        this.handleExternalLinksData(taxonTreatmentsObj, localID, newData);
        break;
    }
  }
  
    /**
  * Processes the taxon data and populates relevant fields such as rank, classification, and taxonTitle.
  * 
  * @param taxonTreatmentsObj - The main object where processed taxa information will be stored.
  * @param localID - The identifier for the current taxa entry.
  * @param newData - The raw data from the Excel sheet for the current taxa entry.
  */
  handleTaxaData(taxonTreatmentsObj: Record<string, TaxonTreatment>, localID: string, newData: Record<string, any>): void {
    // Assign the raw data to the taxonTreatmentsObj object
    taxonTreatmentsObj[localID].taxa = newData;

    // If 'authorship' field exists, rename it to 'authorandyear'
    // to align with the formio schema.
    if (newData['authorship']) {
      newData['authorandyear'] = newData['authorship'];
      delete newData['authorship'];
    }

    // Define the order of taxa classification columns, starting from the most specific rank
    const taxaColumnsOrder = [
      'form', 'variety', 'subspecies', 'species', 'subgenus', 'genus', 'subtribe','tribe',
      'subfamily', 'family', 'superfamily', 'infraorder', 'suborder', 'order', 'superorder', 
      'subclass', 'class', 'superclass', 'subphylum', 'phylum', 'subkingdom', 'kingdom'
    ];
    let rankFound = false;

    // Loop through columns from the most specific rank. Once we find the rank, 
    // the next column with data becomes the classification.
    for (let i = 0; i < taxaColumnsOrder.length; i++) {
      const column = taxaColumnsOrder[i].toLocaleLowerCase();
      if (newData[column] && !rankFound) {
        taxonTreatmentsObj[localID].taxa.rank = taxaColumnsOrder[i];
        rankFound = true;
      } else if (newData[column] && rankFound) {
        taxonTreatmentsObj[localID].taxa.classification = taxonTreatmentsObj[localID].taxa[taxaColumnsOrder[i]];
        break;
      }
    }
  
    // Generate and assign the taxon title and label for the current taxa entry
    const { taxonTitle, label } = this.treeService.generateTaxonTitle(taxonTreatmentsObj[localID].taxa);
    taxonTreatmentsObj[localID].taxa.taxonTitle = taxonTitle;
    taxonTreatmentsObj[localID].taxa.label = label;
  }


    /**
  * @param taxonTreatmentsObj - The main object where processed materials information will be stored.
  * @param localID - The identifier for the current entry.
  * @param newData - The raw data from the Excel sheet for the current materials entry.
  */
  handleMaterialData(taxonTreatmentsObj: Record<string, TaxonTreatment>, localID: string, newData: any): void {
    // Convert the eventDate from Excel's serial format to a JS date format.
    if (newData && newData.eventDate) {
      newData.eventDate = this.excelSerialDateToJSDate(newData.eventDate);
    }

    // Create a new object excluding the __rowNum__ property, as it's not needed.
    const processedData = {};
    for (const key of Object.keys(newData)) {
      if (key !== '__rowNum__' && key !== "typeStatus") {
        processedData[key] = newData[key] + ";&nbsp;";
      } else if (key == "typeStatus") {
        processedData[key] = newData[key];
      }
    }

    taxonTreatmentsObj[localID].materials.push(processedData);
  }

    /** 
  * @param taxonTreatmentsObj - The main object where processed external links information will be stored.
  * @param localID - The identifier for the current entry.
  * @param newData - The raw data from the Excel sheet for the current external links entry.
  */
  handleExternalLinksData(taxonTreatmentsObj: Record<string, TaxonTreatment>, localID: string, newData: any): void {
    // Create a new object excluding the __rowNum__ property, as it's not needed.
    const processedData: any = {};
    for (const key of Object.keys(newData)) {
      if (key !== '__rowNum__') {
        processedData[key] = newData[key];
      }
    }

    // Rename keys to align with the formio schema components' keys.
    for (const key of Object.keys(newData)) {
      if (key !== '__rowNum__') {
        switch (key) {
          case 'Link':
            processedData['link'] = newData[key];
            delete processedData['Link'];
            break;
          case 'Link type':
            processedData['select'] = newData[key];
            delete processedData['Link type'];
            break;
        }
      }
    }

    taxonTreatmentsObj[localID].externalLinks.push(processedData);
  }

  onFileSelected(event: any) {
    if (event.target.files[0].type == "application/vnd.ms-excel") {
      this.isProcessing = true;
  
      if(!event.target.files.length) {
        this.isProcessing = false;
        return;
      }
  
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer = fileReader.result;
        const bstr = this.parseExcel(arrayBuffer);
        const workbook = XLSX.read(bstr, { type: 'binary' });
        this.taxonTreatmentsData = this.convertWorkbookToJson(workbook);
        if (this.taxonTreatmentsData) {
          this.addTaxonTreatments(this.taxonTreatmentsData);
        }
      }
      fileReader.readAsArrayBuffer(event.target.files[0]);
    } else {
      this.invalidFileType();
    }
  }

  invalidFile() {
    this._snackBar.open("Invalid file! Please import file with correct data.",'Ok', {
      duration: 3000
    });
    this.isProcessing = false;
  }
  
  invalidFileType() {
    this._snackBar.open("Invalid file type! Please import an Excel file",'Ok', {
      duration: 3000
    });
    this.isProcessing = false;
  }

    /**
  * @param taxonTreatmentsData - The data extracted from the Excel file containing taxon treatments in key => value pair,
  * where key is the taxonLocalId and value is the taxon treatment associated with it.
  */
  async addTaxonTreatments(taxonTreatmentsData: Record<string, TaxonTreatment>): Promise<void> {
    const filteredSections = getFilteredSectionChooseData(this.treeService.findNodeById(this.section.sectionID), this.treeService);
    const section = filteredSections.find(sec => sec.source === 'template');
    const sectionTemplate = section.template;
    
    const taxonTreatments: TaxonTreatment[] = [];

    Object.keys(taxonTreatmentsData).slice(0,4).forEach(key => {
      taxonTreatments.push(taxonTreatmentsData[key]);
    });
    
    this.totalTaxons = taxonTreatments.length;
    const taxonSectionsData = [];
    const customPropsObj = this.serviceShare.YdocService.customSectionProps.get("customPropsObj");

    const afterRenderFuncs = [];
    
    for (let taxon of taxonTreatments) {
      const taxonSection = this.treeService.addNodeAtPlace(this.section.sectionID, JSON.parse(JSON.stringify(sectionTemplate)), "end");
      customPropsObj[taxonSection.sectionID] = taxon.taxa;
      this.serviceShare.YdocService.customSectionProps.set("customPropsObj", customPropsObj);

      afterRenderFuncs.push(() => this.treeService.renderTaxons(taxonSection, taxon.taxa));

      const materialsSection = taxonSection.children.find(
        (section: articleSection) => section.title.name === '[MM] Materials'
      );

      const externalLinksSection = taxonSection.children.find(
        (section: articleSection) => section.title.name === '[MM] External Links'
      );

      if (taxon.materials.length > 0) {
        afterRenderFuncs.push(this.addMaterialsData(taxon.materials, materialsSection));
      }
      if (taxon.externalLinks.length > 0) {
        afterRenderFuncs.push(() => this.treeService.renderExternalLinks(externalLinksSection, taxon.externalLinks));
      }

      taxonSectionsData.push(taxonSection);
      

      this.processedTaxons = taxonSectionsData.length;
      this.progress = (this.processedTaxons / this.totalTaxons) * 100;

      await new Promise(resolve => setTimeout(resolve, 10));
    }
    this.treeService.setArticleSectionStructureFlat();

    this.isProcessing = false;
    this.isRendering = true;

    setTimeout(() => {
      afterRenderFuncs.forEach(func => func());
  
      setTimeout(() => {
        const taxonSections = taxonSectionsData.map((sec: articleSection) => this.serviceShare.YdocService.getSectionByID(sec.sectionID));
        this.treeService.treeVisibilityChange.next({ action: 'importTaxonsChange', parentContainerID: this.section.sectionID, taxonSections });

        // this.treeService.applyTaxonImportChange(taxonSections, this.section.sectionID);
        this.dialogRef.close();
      }, 100);
    }, 200);
  }

  addMaterialsData(materialsData: Record<string, any>[], materialSection: articleSection) {
    const materialSectionsData = [];

    for (const row of materialsData) {
      const materialData = JSON.parse(JSON.stringify(material));
      materialData.mode = "";
      materialData.active = false;
      materialData.defaultFormIOValues = row;
      materialData.sectionID = uuidv4();
      materialData.label = row.typeStatus;
      materialData.parentId = materialSection.sectionID;
      materialSectionsData.push(materialData);
    }

    const { data, nodeForm } = this.treeService.orderMaterialSections(materialSectionsData, new FormGroup({}));
    materialSection.defaultFormIOValues = data;

    const newNodes = [];
    materialSectionsData.forEach((sec: any) => {
      const newNode = this.treeService.addNodeAtPlace(materialSection.sectionID, sec, "end") as articleSection;
      this.serviceShare.YdocService.articleSectionsMap.set(newNode.sectionID, newNode);
      materialSection.children.push(newNode);
      this.serviceShare.YdocService.saveSectionMenusAndSchemasDefs([getSectionBasicStructure(this.serviceShare.YdocService)(newNode)]);
      newNodes.push(newNode);
    })

    this.serviceShare.YdocService.articleSectionsMap.set(materialSection.sectionID, materialSection);
    return () => this.treeService.renderMaterials(materialSection, nodeForm);
  }
}