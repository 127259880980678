import { Component, EventEmitter, Input, OnInit, Output, ViewChild,ChangeDetectionStrategy, ElementRef, ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import { UntypedFormControl} from '@angular/forms';
import {TreeService} from '@app/editor/meta-data-tree/tree-service/tree.service';
import {articleSection} from '@app/editor/utils/interfaces/articleSection';
import {HttpClient} from "@angular/common/http";
import {HelperService} from "@app/editor/section/helpers/helper.service";
import {Observable, Subject} from "rxjs";
import {startWith, map} from "rxjs/operators";
import { ServiceShare } from '@app/editor/services/service-share.service';
import { customSecInterface } from '../funder-section/funder-section.component';

export interface DwCTerms {
  name: string;
  value: string;
  tab: string;
}
@Component({
  selector: 'app-material-section',
  templateUrl: './material-section.component.html',
  styleUrls: ['./material-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialSectionComponent implements OnInit, customSecInterface {

  @Input() onSubmit!: (data: any) => Promise<any>;
  @Output() onSubmitChange = new EventEmitter<(data: any) => Promise<any>>();

  @Input() section!: articleSection;
  @Output() sectionChange = new EventEmitter<articleSection>();

  @Input() triggerCustomSecSubmit: Subject<any>;
  @Output() triggerCustomSecSubmitChange = new EventEmitter<Subject<any>>();

  @ViewChildren('category') categoryElements: QueryList<ElementRef>;

  selected = new UntypedFormControl(0);
  //@ts-ignore
  materialStructure: any = {categories: {} as any};
  tabs;
  render = false;
  typeStatus!: UntypedFormControl;
  typeHeading!: UntypedFormControl;
  searchdarwincore: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<DwCTerms[]>;
  props;
  examplesTextMaxLength = 60;
  examplesTextLengthThreshold = 170;
  activeCategoryIndex: number = 0;

  isLong(text: string) {
    return text.length > this.examplesTextLengthThreshold;
  }

  trackByCategoryId(index: number, item: any): any {
    return item.key;
  }

  trackByEntryId(index: number, item: any): any {
    return item.key;
  }

  constructor(
    private treeService: TreeService,
    public http: HttpClient,
    public helperService: HelperService,
    public serviceShare: ServiceShare,
    private cdr: ChangeDetectorRef
  ) {
  }

  onVisibilityChange(visible: boolean, index: number) {
    if (visible) {
      this.activeCategoryIndex = index;
    }
  }

  scrollToCategory(index: number): void {
    const categoryArray = this.categoryElements.toArray();
    if (categoryArray[index]) {
      categoryArray[index].nativeElement.scrollIntoView();
    }
  }

  clearField() {
    this.searchdarwincore.setValue('');
  }

  private _filter(name: string): DwCTerms[] {
    const filterValue = name.toLowerCase();
    return this.props.filter(option => option.value.toLowerCase().includes(filterValue));
  }
  displayFn(term: DwCTerms): string {
    return term && term.name ? term.name : '';
  }
  ngOnInit(): void {
    if(this.section.title.name == "[MM] Materials") {
      this.materialStructure = this.section.originalSectionTemplate.schema.override;
    } else {
      const parent = this.treeService.ydocService.getSectionByID(this.section.originalSectionTemplate.parentId);
      this.materialStructure = parent.originalSectionTemplate.schema.override;
    }
    this.tabs = Object.keys(this.materialStructure.categories);
    this.props = this.tabs.map((key: string) => {
      return this.materialStructure.categories[key].entries.map((entry: any) => {
        return {
          'name' : entry.localName + ' (' + key + ')',
          'value' : entry.localName,
          'tab' : key,
        }
      })
    }).flat();

    this.typeStatus = new UntypedFormControl();
    let customPropsObj = this.serviceShare.YdocService.customSectionProps.get('customPropsObj')
    let sectionCustomData = customPropsObj[this.section.sectionID];

    this.props.forEach((control: DwCTerms) => {
      if(sectionCustomData && sectionCustomData[control.value]){
        const lastIndex = (sectionCustomData[control.value] as string).lastIndexOf(";");
        let value: any;
        if(lastIndex) {
          value = sectionCustomData[control.value].split("")
          value[lastIndex] = "";
          value = value.join("");
        } else {
          value = sectionCustomData[control.value]
        }
          
        this[`${control.value}${control.tab}`] = new UntypedFormControl(value);
      } else {
        this[`${control.value}${control.tab}`] = new UntypedFormControl(null);
      }
    });

    if(!this.typeStatus.value) {
      if(sectionCustomData && sectionCustomData.typeStatus) {
        this.typeStatus.setValue(sectionCustomData.typeStatus);
      } else {
        this.typeStatus.setValue("Halotype");
      }
    }

    this.filteredOptions = this.searchdarwincore.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.props.slice();
      })
    );

    // Show loading indicator while dom is rendering
    setTimeout(() => {
      this.render = true;
      this.cdr.detectChanges();
    }, 10);

    this.triggerCustomSecSubmit.subscribe(()=>{
      this.triggerSubmit()
    })
  }

  optionSelected(event: any, tabs: any[]) {
    let element = event.option.value.value.toLowerCase() + event.option.value.tab.toLowerCase();
    const index = tabs.findIndex(tab => {
      return tab.value.entries.some(el => (el.localName.toLowerCase() + tab.key.toLowerCase())  === element);
    });
    this.selected.setValue(index);
    setTimeout(() => {
      const el = document.getElementById(element);
      if(el) {
        el.focus();
      }
    }, 300)
  }

  @Output() async triggerSubmit() {
    let data: any = {
      typeStatus: this.typeStatus.value || "Other material",
    }

    this.props.forEach((prop: DwCTerms) => {
      if(prop.value !== "typeStatus") {
        if(this[`${prop.value}${prop.tab}`] && this[`${prop.value}${prop.tab}`].value) {
          data[prop.value] = this[`${prop.value}${prop.tab}`].value.trim() + ";&nbsp;";
        }
      }
    });
    Object.keys(data).forEach(key => {
      if (data[key] === undefined || data[key] === null) {
        if (key !== 'typeHeading' && key !== 'typeStatus') {
          delete data[key];
        }
      }
    })
    await this.onSubmit({data});
  }
}
