import { AfterViewInit, Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-cant-open-article-dialog',
  templateUrl: './cant-open-article-dialog.component.html',
  styleUrls: ['./cant-open-article-dialog.component.scss']
})
export class CantOpenArticleDialogComponent implements AfterViewInit, OnDestroy {
  overlay: HTMLDivElement;

  ngAfterViewInit(): void {
    this.overlay = document.querySelector(".cdk-overlay-backdrop") as HTMLDivElement;
    if(this.overlay) {
      this.overlay.style.backdropFilter = "blur(20px)";
    }
  }
  
  ngOnDestroy(): void {
    if(this.overlay) {
      const spinner = (document.querySelector(".spinner-container") as HTMLDivElement);
      spinner.style.opacity = "1";
      spinner.style.backdropFilter = "blur(20px)";
      
      setTimeout(() => {
        this.overlay.style.backdropFilter = "unset";
        spinner.style.opacity = "unset";
        spinner.style.backdropFilter = "unset";
      }, 3000)
    }
  }
}
