import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppConfig, APP_CONFIG } from "@app/core/services/app-config";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";



@Component({
    selector: 'app-autocomplete-modal',
    templateUrl: './autocomplete-modal.component.html',
    styleUrls: ['./autocomplete-modal.component.scss'],
  })
export class AutocompleteModalComponent implements OnInit, AfterViewInit{
  title: string;

  @ViewChild('input', { read: ElementRef }) input: ElementRef;



  searchTaxonControl = new UntypedFormControl();
  loading = false;
  searchData = [];
  subscription = new Subscription();

  selectedData = [];
  result = "";
  
  constructor(
    private httpClient: HttpClient,
    private dialogRef: MatDialogRef<AutocompleteModalComponent>,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }
    ) {
    this.title = this.data.title;

  }

  ngOnInit(): void {
    this.subscription.add(this.searchTaxonControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
    ).subscribe((value: any) => {
      if (value && typeof value == "string" && value.trim().length > 0) {
        this.searchTaxon(value);
      }
    }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input?.nativeElement?.focus();
    }, 10);
  }
  
  oldSub = new Subscription();
  searchTaxon(value: string) {
    if (this.oldSub) {
      this.oldSub.unsubscribe()
    }
    this.searchData = [];
    this.loading = true;

    this.oldSub = this.httpClient.get(this.config.taxonSearch + `/taxons/${value}`).subscribe({
      next: (responce: any[]) => {
        this.searchData = responce;
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
      }
    })
  }

  select(event: Event, row: any) {
    event.preventDefault();
    this.selectedData.push(row.scientificname);
    this.result = this.selectedData.join(", ");
  }

  displayFn(option: any): string {
    return "";
  }

  saveHandler() {
    this.dialogRef.close(this.selectedData);
  }
}