export const treatmentSectionsDescription = {
  "id": 9980,
  "name": "[MM] Description",
  "label": "Description",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Description",
        "placeholder": "Description",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\">Description</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}

export const treatmentSectionsDiagnosis = {
  "id": 9979,
  "name": "[MM] Diagnosis",
  "label": "Diagnosis",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Diagnosis",
        "placeholder": "Diagnosis",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\">Diagnosis</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}

export const treatmentSectionsDistribution = {
  "id": 9977,
  "name": "[MM] Distribution",
  "label": "Distribution",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Distribution",
        "placeholder": "Distribution",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\">Distribution</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}


export const treatmentSectionsEcology = {
  "id": 9976,
  "name": "[MM] Ecology",
  "label": "Ecology",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Ecology",
        "placeholder": "Ecology",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\">Ecology</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}

export const treatmentSectionsConservation = {
  "id": 9975,
  "name": "[MM] Conservation",
  "label": "Conservation",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Conservation",
        "placeholder": "Conservation",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold !important;\">Conservation</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}

export const treatmentSectionsBiology = {
  "id": 9974,
  "name": "[MM] Biology",
  "label": "Biology",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Biology",
        "placeholder": "Biology",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\">Biology</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}

export const treatmentSectionsTaxonDiscussion = {
  "id": 9973,
  "name": "[MM] Taxon discussion",
  "label": "Taxon discussion",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Taxon discussion",
        "placeholder": "Taxon discussion",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\">Taxon discussion</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}

export const treatmentSectionsNotes = {
  "id": 9972,
  "name": "[MM] Notes",
  "label": "Notes",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "schema": {
    "components": [
      {
        "label": "Notes",
        "placeholder": "Notes",
        "autoExpand": false,
        "tableView": true,
        "key": "paragraph",
        "type": "textfield",
        "input": true,
        "prefix": "",
        "customClass": "",
        "suffix": "",
        "multiple": false,
        "defaultValue": null,
        "protected": false,
        "unique": false,
        "persistent": true,
        "hidden": false,
        "clearOnHide": true,
        "refreshOn": "",
        "redrawOn": "",
        "modalEdit": false,
        "dataGridLabel": false,
        "labelPosition": "top",
        "description": "",
        "errorLabel": "",
        "tooltip": "",
        "hideLabel": false,
        "tabindex": "",
        "disabled": false,
        "autofocus": false,
        "dbIndex": false,
        "customDefaultValue": "",
        "calculateValue": "",
        "calculateServer": false,
        "widget": {
          "type": "input"
        },
        "attributes": [],
        "validateOn": "change",
        "validate": {
          "required": false,
          "custom": "",
          "customPrivate": false,
          "strictDateValidation": false,
          "multiple": false,
          "unique": false,
          "minLength": "",
          "maxLength": "",
          "pattern": "",
          "minWords": "",
          "maxWords": ""
        },
        "conditional": {
          "show": null,
          "when": null,
          "eq": ""
        },
        "overlay": {
          "style": "",
          "left": "",
          "top": "",
          "width": "",
          "height": ""
        },
        "allowCalculateOverride": false,
        "encrypted": false,
        "showCharCount": false,
        "showWordCount": false,
        "properties": [],
        "allowMultipleMasks": false,
        "addons": [],
        "mask": false,
        "inputType": "text",
        "inputFormat": "html",
        "inputMask": "",
        "displayMask": "",
        "spellcheck": true,
        "truncateMultipleSpaces": false,
        "rows": 3,
        "wysiwyg": false,
        "editor": "",
        "fixedSize": true,
        "id": "ew3is1sc"
      }
    ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\">Notes</p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}

export const treatmentSectionsCustom = {
  "id": 9971,
  "name": "[MM] Custom",
  edit: {active: true, main: true},
  add: {active: false, main: false},
  delete: {active: false, main: false},
  select: {active: true, main: true},
  "label": "{{data.title}}",
  "schema": {
      "components": [
        {
          "label": "Title of Custom Section",
          "defaultValue": "Title of Custom Section",
          "placeholder": "Custom Section Title",
          "autoExpand": false,
          "tableView": true,
          "key": "title",
          "type": "textfield",
          "input": true,
          "prefix": "",
          "customClass": "",
          "suffix": "",
          "multiple": false,
          "protected": false,
          "unique": false,
          "persistent": true,
          "hidden": false,
          "clearOnHide": true,
          "refreshOn": "",
          "redrawOn": "",
          "modalEdit": false,
          "dataGridLabel": false,
          "labelPosition": "top",
          "description": "",
          "errorLabel": "",
          "tooltip": "",
          "hideLabel": false,
          "tabindex": "",
          "disabled": false,
          "autofocus": false,
          "dbIndex": false,
          "customDefaultValue": "",
          "calculateValue": "",
          "calculateServer": false,
          "widget": {
            "type": "input"
          },
          "attributes": [],
          "validateOn": "change",
          "validate": {
            "required": false,
            "custom": "",
            "customPrivate": false,
            "strictDateValidation": false,
            "multiple": false,
            "unique": false,
            "minLength": "",
            "maxLength": "",
            "pattern": "",
            "minWords": "",
            "maxWords": ""
          },
          "conditional": {
            "show": null,
            "when": null,
            "eq": ""
          },
          "overlay": {
            "style": "",
            "left": "",
            "top": "",
            "width": "",
            "height": ""
          },
          "allowCalculateOverride": false,
          "encrypted": false,
          "showCharCount": false,
          "showWordCount": false,
          "properties": [],
          "allowMultipleMasks": false,
          "addons": [],
          "mask": false,
          "inputType": "text",
          "inputFormat": "html",
          "inputMask": "",
          "displayMask": "",
          "spellcheck": true,
          "truncateMultipleSpaces": false,
          "rows": 3,
          "wysiwyg": false,
          "editor": "",
          "fixedSize": true,
          "id": "ew3is1sa"
        },
        {
          "label": "Custom",
          "placeholder": "Custom",
          "autoExpand": false,
          "tableView": true,
          "key": "paragraph",
          "type": "textfield",
          "input": true,
          "prefix": "",
          "customClass": "",
          "suffix": "",
          "multiple": false,
          "defaultValue": null,
          "protected": false,
          "unique": false,
          "persistent": true,
          "hidden": false,
          "clearOnHide": true,
          "refreshOn": "",
          "redrawOn": "",
          "modalEdit": false,
          "dataGridLabel": false,
          "labelPosition": "top",
          "description": "",
          "errorLabel": "",
          "tooltip": "",
          "hideLabel": false,
          "tabindex": "",
          "disabled": false,
          "autofocus": false,
          "dbIndex": false,
          "customDefaultValue": "",
          "calculateValue": "",
          "calculateServer": false,
          "widget": {
            "type": "input"
          },
          "attributes": [],
          "validateOn": "change",
          "validate": {
            "required": false,
            "custom": "",
            "customPrivate": false,
            "strictDateValidation": false,
            "multiple": false,
            "unique": false,
            "minLength": "",
            "maxLength": "",
            "pattern": "",
            "minWords": "",
            "maxWords": ""
          },
          "conditional": {
            "show": null,
            "when": null,
            "eq": ""
          },
          "overlay": {
            "style": "",
            "left": "",
            "top": "",
            "width": "",
            "height": ""
          },
          "allowCalculateOverride": false,
          "encrypted": false,
          "showCharCount": false,
          "showWordCount": false,
          "properties": [],
          "allowMultipleMasks": false,
          "addons": [],
          "mask": false,
          "inputType": "text",
          "inputFormat": "html",
          "inputMask": "",
          "displayMask": "",
          "spellcheck": true,
          "truncateMultipleSpaces": false,
          "rows": 3,
          "wysiwyg": false,
          "editor": "",
          "fixedSize": true,
          "id": "ew3is1sb"
        }
      ]
  },
  "sections": [],
  "template": "<ng-container *ngIf='data.paragraph'><h4 style=\"padding-left: 30px;font-weight: bold !important;\" contenteditableNode='false'><p style=\"font-weight: bold;\"><form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"title\">\n  \t</form-field></p></h4><inline-block-container contenteditableNode='false' style=\"display: block;padding-left: 30px;\">\n\t<form-field contenteditableNode='false' class=\"set-align-left\" formControlName=\"paragraph\">\n  \t</form-field>\n</inline-block-container></ng-container>",
  "type": 1,
  "version_id": 501,
  "version": 3,
  "version_pre_defined": false,
  "version_date": "2022-06-05T12:21:08.000000Z",
  "complex_section_settings": null,
  "settings": null,
  "compatibility": null,
  "created_at": "2022-06-05T12:09:24.000000Z"
}
